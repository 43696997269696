import { createApp, config, compilerOptions  } from 'vue'
import App from './App.vue'
import router from './router'
import { onAuthStateChanged } from "firebase/auth";

// Vuetify
import 'vuetify/styles'
import { createVuetify, ThemeDefinition } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { aliases, fa } from 'vuetify/iconsets/fa'
import { mdi } from 'vuetify/iconsets/mdi'


import { auth } from '@/firebase/config.js';
import './assets/tailwind.css'
import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.css'





const lightTheme = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#6200EE',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
    btnMenu: '#a6adba'

  }
}

const darkTheme = {
  dark: true,
  colors: {
    background: '#181c25',
    surface: '#252b37',
    primary: '#64C3A5',
    'primary-darken-1': '#3700B3',
    'primary-lighter':'#a8f0d9',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#f87272',
    btnMenu: '#a6adba'
  }
}




const vuetify = createVuetify({
    theme:{
      defaultTheme: 'darkTheme',
      themes: {
        lightTheme,
        darkTheme
      }
    },
    icons: {
      defaultSet: 'fa',
      aliases,
      sets: {
        fa,
        mdi,
      }
    },
    components,
    directives,
})



let app

onAuthStateChanged(auth, (_user) => {
    if (!app) {

        app = createApp(App)
            .use(router).use(vuetify)
            .mount('#app')
            
    } else {
 
    }
  })


