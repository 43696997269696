<template>

<div class=" pt-[64px] px-0">
    

    <div class=" grid grid-col-1 mt-8">

        
        <!-- <div class="grid grid-cols-1 sm:grid-cols-4 lg:grid-cols-8 justify-items-center grid-flow-row-dense gap-5 md:gap-10 p-0">

          <PairScore :pairs="pairs"  @sendPowFinal="recieveFinal" />

        </div> -->

        <div class="mb-5 flex justify-center items-center">

          <div class=" w-full xl:w-1/2 ">

          <div class="flex items-center justify-between">
          <p class="text-lg card-title tracking-[0.30em] text-white w-1/2 sm:w-full ">FOREX PAIRS</p>
          <button class=" btn btn-sm text-[13px] xl:text-[11px]  rounded-[5px] tracking-[0.20em] text-secondary" @click="handleUpdate()">Update Pairs</button>
          </div>


          <div class="divider"></div>

          <div class="w-full">

            <div class=" columns-2 gap-5 md:columns-4 md:gap-8">
              <PairScore :pairs="pairs"   />
            </div>

          </div>

      
        </div>

        </div>

        



    </div>

</div>






</template>
  
  <script>

//component 
import PairScore from '@/components/score/PairScore.vue'
import SingleIndex from '@/components/score/SingleIndex.vue'
//composables
import getPairs from '@/composables/getPairs'
import updatePowers from '@/composables/updatePowers'
//libraries
import { db } from '@/firebase/config.js';
import { doc, setDoc } from "firebase/firestore"; 
import { ref } from '@vue/reactivity';
import { onMounted, onUpdated } from '@vue/runtime-core';
import { useRouter } from 'vue-router'




export default {

components: { PairScore, updatePowers, SingleIndex },

setup(){

  //Get Main Pairs
  const { pairs, error, load} = getPairs()
  load()

  const { upPower, errorUp } = updatePowers()
  const router = useRouter()


  //RECIBIR POWER DE CADA PAR 
  const recieveFinal = () =>{

    
  }


    onUpdated(() => {
            
          
    })

    const handleUpdate = ()=>{

      upPower("v1")
      router.push({name: 'main'})

    }


    
    //create a document // Edit Document

    // const createPair = async () => { await setDoc(doc(db, "pares_hijos", "U-USDJPY"), {
      
    //   hijo: 'JPY',
    //   id: 'U-USDJPY',
    //   id_inverso:'J-USDJPY',
    //   nombreCorr: "USDJPY",
    //   papa: 'USD',
    //   powerHijo: 0,
    //   powerPapa: 0
      
    // })


    // }

    const createPair = async () => { await setDoc(doc(db, "pares_hijos", "I-AUS200"), {
      
 
      id: 'I-AUS200',
      nombreCorr: "AUS200",
      powerHijo: 0, 
      par: 'IND'
      
    })


    }


  return{ pairs,
          createPair,
          recieveFinal, 
       
         
    
    
          errorUp,
          updatePowers,
          handleUpdate }



}


}
</script>
  
<style scoped>




</style>