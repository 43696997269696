   /**
 * Tool for creating image Blocks for Editor.js
 * Made with «Creating a Block Tool» tutorial {@link https://editorjs.io/creating-a-block-tool}
 *
 * @typedef {object} ImageToolData — Input/Output data format for our Tool
 * @property {string} url - image source URL
 * @property {string} caption - image caption
 * @property {boolean} withBorder - flag for adding a border
 * @property {boolean} withBackground - flag for adding a background
 * @property {boolean} stretched - flag for stretching image to the full width of content
 *
 * @typedef {object} ImageToolConfig
 * @property {string} placeholder — custom placeholder for URL field
 */
   export default class SimpleImage {
    /**
     * Our tool should be placed at the Toolbox, so describe an icon and title
     */
    static get toolbox() {
      return {
        title: 'Chart',
        icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3v11.25A2.25 2.25 0 006 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0118 16.5h-2.25m-7.5 0h7.5m-7.5 0l-1 3m8.5-3l1 3m0 0l.5 1.5m-.5-1.5h-9.5m0 0l-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6" /></svg>'
      };
    }
  
    /**
     * Allow render Image Blocks by pasting HTML tags, files and URLs
     * @see {@link https://editorjs.io/paste-substitutions}
     * @return {{tags: string[], files: {mimeTypes: string[], extensions: string[]}, patterns: {image: RegExp}}}
     */
    static get pasteConfig() {
      return {
        tags: ['IMG'],
        files: {
          mimeTypes: ['image/*'],
          extensions: ['gif', 'jpg', 'png'] // You can specify extensions instead of mime-types
        },
        patterns: {
          image: /https?:\/\/\S+\.(gif|jpe?g|tiff|png)$/i
        }
      }
    }
  
    /**
     * Automatic sanitize config
     * @see {@link https://editorjs.io/sanitize-saved-data}
     */
    static get sanitize(){
      return {
        url: {},
        caption: {
          b: true,
          a: {
            href: true
          },
          i: true
        }
      }
    }
  
    /**
     * Tool class constructor
     * @param {ImageToolData} data — previously saved data
     * @param {object} api — Editor.js Core API {@link  https://editorjs.io/api}
     * @param {ImageToolConfig} config — custom config that we provide to our tool's user
     */
    constructor({data, api, config, readOnly}){
      this.api = api;
      this.readOnly = readOnly;
      this.config = config || {};
      this.data = {
        url: data.url || '',
        //caption: data.caption || '',
        //withBorder: data.withBorder !== undefined ? data.withBorder : false,
        //withBackground: data.withBackground !== undefined ? data.withBackground : false,
        //stretched: data.stretched !== undefined ? data.stretched : false,
      };
  
      this.wrapper = undefined;
      // this.settings = [
      //   {
      //     name: 'withBorder',
      //     icon: `<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15.8 10.592v2.043h2.35v2.138H15.8v2.232h-2.25v-2.232h-2.4v-2.138h2.4v-2.28h2.25v.237h1.15-1.15zM1.9 8.455v-3.42c0-1.154.985-2.09 2.2-2.09h4.2v2.137H4.15v3.373H1.9zm0 2.137h2.25v3.325H8.3v2.138H4.1c-1.215 0-2.2-.936-2.2-2.09v-3.373zm15.05-2.137H14.7V5.082h-4.15V2.945h4.2c1.215 0 2.2.936 2.2 2.09v3.42z"/></svg>`
      //   },
      //   {
      //     name: 'stretched',
      //     icon: `<svg width="17" height="10" viewBox="0 0 17 10" xmlns="http://www.w3.org/2000/svg"><path d="M13.568 5.925H4.056l1.703 1.703a1.125 1.125 0 0 1-1.59 1.591L.962 6.014A1.069 1.069 0 0 1 .588 4.26L4.38.469a1.069 1.069 0 0 1 1.512 1.511L4.084 3.787h9.606l-1.85-1.85a1.069 1.069 0 1 1 1.512-1.51l3.792 3.791a1.069 1.069 0 0 1-.475 1.788L13.514 9.16a1.125 1.125 0 0 1-1.59-1.591l1.644-1.644z"/></svg>`
      //   },
      //   {
      //     name: 'withBackground',
      //     icon: `<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10.043 8.265l3.183-3.183h-2.924L4.75 10.636v2.923l4.15-4.15v2.351l-2.158 2.159H8.9v2.137H4.7c-1.215 0-2.2-.936-2.2-2.09v-8.93c0-1.154.985-2.09 2.2-2.09h10.663l.033-.033.034.034c1.178.04 2.12.96 2.12 2.089v3.23H15.3V5.359l-2.906 2.906h-2.35zM7.951 5.082H4.75v3.201l3.201-3.2zm5.099 7.078v3.04h4.15v-3.04h-4.15zm-1.1-2.137h6.35c.635 0 1.15.489 1.15 1.092v5.13c0 .603-.515 1.092-1.15 1.092h-6.35c-.635 0-1.15-.489-1.15-1.092v-5.13c0-.603.515-1.092 1.15-1.092z"/></svg>`
      //   }
      // ];

      

    }
  
    /**
     * Return a Tool's UI
     * @return {HTMLElement}
     */

   
   

    render = (readOnly) =>{

      
      this.wrapper = document.createElement('div');
      this.wrapper.classList.add('simple-image');
  
      if (this.data && this.data.url){
        this._createImage(this.data.url, this.data.caption);
        return this.wrapper;
      }
  
      const input = document.createElement('input');
  
      
      
      if(this.readOnly == true){
        //console.log(this.readOnly)
        input.placeholder = this.config.placeholder || 'Image link empty...';
        input.disabled = true
        input.classList.add("hidden");
      }

      if(this.readOnly == false){
       // console.log(this.readOnly)
        input.placeholder = this.config.placeholder || 'Paste Tradingview screenshot link...';
        input.disabled = false
      }
     
      
      input.addEventListener('paste', (event) => {
        this._createImage(event.clipboardData.getData('text'));
      });
  
      this.wrapper.appendChild(input);
  
      return this.wrapper;
    }

    
  
    /**
     * @private
     * Create image with caption field
     * @param {string} url — image source
     * @param {string} captionText — caption value
     */
    _createImage(url, captionText){
      const image = document.createElement('img');
      const link = document.createElement('a');
      const zoom = document.createElement('span')
      //const caption = document.createElement('div');



      link.href = "javascript:void(0)";
      //link.setAttribute("target", "_blank");
      link.setAttribute("onclick", "showModal('"+url+"')");
      this.wrapper.setAttribute("onmouseover", "zoomFun(this,true)");
      this.wrapper.setAttribute("onmouseout", "zoomFun(this, false)");
    
      link.classList.add("mystyle");

      zoom.classList.add("fa-solid");
      zoom.classList.add("fa-magnifying-glass");
      image.classList.add("zoomImg");





      link.classList.add("zoomIcon", "flex", "items-center", "justify-center", "w-[30px]", "h-[30px]", "no-underline", "bg-slate-200", "ease-in", "duration-300");

      image.src = url;
      //caption.contentEditable = true;
      //caption.innerHTML = captionText || '';


  
      this.wrapper.innerHTML = '';
      link.appendChild(zoom);
      //image.appendChild(link);
      
      this.wrapper.appendChild(image);
      this.wrapper.appendChild(link);
      //this.wrapper.appendChild(caption);
  
      //this._acceptTuneView();
    }
  
    /**
     * Extract data from the UI
     * @param {HTMLElement} blockContent — element returned by render method
     * @return {SimpleImageData}
     */
    save(blockContent){
      const image = blockContent.querySelector('img');
      //const caption = blockContent.querySelector('[contenteditable]');
  
      if (!image) {
        console.log('img src is empty');
      } else {
        console.log('igual entra');
        return Object.assign(this.data, {

          url: image.src || 'https:'
          //caption: caption.innerHTML || ''
        });
      }

      

       
    }
  
    /**
     * Skip empty blocks
     * @see {@link https://editorjs.io/saved-data-validation}
     * @param {ImageToolConfig} savedData
     * @return {boolean}
     */
    // validate(savedData){
    //   if (!savedData.url.trim()){
    //     return false;
    //   }
  
    //   return true;
    // }
  
    static get isReadOnlySupported() {
      return true;
    }




  
    // /**
    //  * Making a Block settings: 'add border', 'add background', 'stretch to full width'
    //  * @see https://editorjs.io/making-a-block-settings — tutorial
    //  * @see https://editorjs.io/tools-api#rendersettings - API method description
    //  * @return {HTMLDivElement}
    //  */
    // renderSettings(){
    //   const wrapper = document.createElement('div');
  
    //   this.settings.forEach( tune => {
    //     let button = document.createElement('div');
  
    //     button.classList.add(this.api.styles.settingsButton);
    //     button.classList.toggle(this.api.styles.settingsButtonActive, this.data[tune.name]);
    //     button.innerHTML = tune.icon;
    //     wrapper.appendChild(button);
  
    //     button.addEventListener('click', () => {
    //       this._toggleTune(tune.name);
    //       button.classList.toggle(this.api.styles.settingsButtonActive);
    //     });
  
    //   });
  
    //   return wrapper;
    // }
  

    // /**
    //  * @private
    //  * Click on the Settings Button
    //  * @param {string} tune — tune name from this.settings
    //  */
    // _toggleTune(tune) {
    //   this.data[tune] = !this.data[tune];
    //   this._acceptTuneView();
    // }
  
    // /**
    //  * Add specified class corresponds with activated tunes
    //  * @private
    //  */
    // _acceptTuneView() {
    //   this.settings.forEach( tune => {
    //     this.wrapper.classList.toggle(tune.name, !!this.data[tune.name]);
  
    //     if (tune.name === 'stretched') {
    //       this.api.blocks.stretchBlock(this.api.blocks.getCurrentBlockIndex(), !!this.data.stretched);
    //     }
    //   });
    // }
  



    /**
     * Handle paste event
     * @see https://editorjs.io/tools-api#onpaste - API description
     * @param {CustomEvent }event
     */
    onPaste(event){
      switch (event.type){
        case 'tag':
          const imgTag = event.detail.data;
          this._createImage(imgTag.src);
          break;
        case 'file':
          /* We need to read file here as base64 string */
          const file = event.detail.file;
          const reader = new FileReader();
  
          reader.onload = (loadEvent) => {
            this._createImage(loadEvent.target.result);
          };
  
          reader.readAsDataURL(file);
          break;
        case 'pattern':
          const src = event.detail.data;
  
          this._createImage(src);
          break;
      }
    }
  }

