import { ref } from "vue";
import { collection, getDocs } from 'firebase/firestore'
import { doc, onSnapshot, query, where } from "firebase/firestore";
import { db } from '@/firebase/config.js';


const getSubPairsInd = (props) => {

    const subPairsInd = ref(null)
    const errorSub = ref(null)
    //const poderPapa = ref(null)
    //const poderPapaResult = ref()
    

  
    
    const loadSubPairsInd = async () => {

        try{

        const q = query(collection(db, "pares_hijos"), where("par", "==", props));
        const paresCompletos = await onSnapshot(q, (querySnapshot) => {

            const paresHijos = [];
            //const poderPapaSum = []
            //poderPapaResult2.push({ nombre: props.pair.nombre})
           
            
            querySnapshot.forEach((doc) => {
                paresHijos.push(doc.data());
                //poderPapaSum.push(doc.data().powerHijo);
                
                

            });


            subPairsInd.value = paresHijos
            //poderPapa.value = poderPapaSum

            // let result = subPairs.value.reduce((sum, e) => {
            //     (e.papa == e.papa) ? (sum += e.powerHijo) : (sum += 0)
            //     return sum;
            //   }, 0);
            

            //poderPapaResult.value = result
           // poderPapaResult2.push({power: result})
           // console.log(subPairsInd)
            //console.log(poderPapaResult);
            //console.log(result);
            //console.log(poderPapaResult.value);

            
            

            
            
        });

        
        }

        catch(err){
            errorSub.value = err.message
            console.log(errorSub.value)
      }
    }

    return { subPairsInd, errorSub, loadSubPairsInd}

}

export default getSubPairsInd