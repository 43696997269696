<template>


<div  class="flex items-center gap-5">
            <v-avatar v-if="infoUserName.author_photoURL == ''" class="h-[40px] w-[40px] bg-primary">

             
                <p class="font-semibold text-lg"> {{ infoUserName.displayName.charAt(0) }} </p>
            </v-avatar>
            <v-avatar v-else class="h-[40px] w-[40px] ">
          
              <v-img  :src="infoUserName.author_photoURL"></v-img>
               
              
            </v-avatar>
              
            

            <div class="flex flex-col grow">
            
              <router-link  class="flex gap-2 items-center" :to="{ name: 'detailsPost', params: { id: props.post.id }}">
                
              
              <p class="text-neutral-300 font-semibold"> {{props.post.title}} </p>

              
              



              
        
                

              </router-link>

          

              <div class="flex gap-2">
                
                
                <span class="text-xs font-medium text-neutral-300 text-primary">{{ infoUserName.displayName }}</span>
                <span class="capitalize text-xs font-medium italic text-neutral-300"> [ {{props.post.category }} ]  </span>
                
          
                
         
                <span class="text-xs font-medium italic text-neutral-400">{{ timeAgo(props.post.time)}} </span>
              </div>
              
            </div>

            <div v-if="(props.filter == 'mine') && (props.post.status == 'public')"  class="text-primary flex gap-1">
              <span v-if="(props.filter == 'mine') && (props.post.status == 'public')" class="capitalize text-xs font-medium italic text-primary"> {{props.post.status }}</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
                  <path fill-rule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clip-rule="evenodd" />
                </svg>
                

              </div>

              <div v-if="(props.filter == 'mine') && (props.post.status == 'draft')"  class="text-orange-400 flex gap-1">
                <span v-if="(props.filter == 'mine') && (props.post.status == 'draft')" class="capitalize text-xs font-medium italic text-orange-400"> {{props.post.status }}</span>

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
                  <path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />
                  <path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />
                </svg>
                
              </div>
              
              
              <div v-if="(props.filter == 'mine') && (props.post.status == 'hidden')" class="text-neutral-500 flex gap-1">
                <span v-if="(props.filter == 'mine') && (props.post.status == 'hidden')" class="capitalize text-xs font-medium itali"> {{props.post.status }}</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 h-4">
                  <path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z" />
                  <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z" />
                  <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z" />
                </svg>
                

              </div>
            
          </div>




    

      






            

            




</template>

<script>


import getInfoUser  from "@/composables/forum/getInfoUser"

//imports vue
import { ref } from "vue"
import getTimeAgo from "@/composables/forum/timeAgo"
import getUser from "@/composables/getUser"








export default {

     props: [ 'post', 'filter' ],
     setup(props){

         //get domposables functions
        
        const { timeAgo } = getTimeAgo()
        let authoruid = props.post.author_id
        const { loadInfoUser, infoUserName } = getInfoUser()

       


        

        
        const { user } = getUser()
        loadInfoUser(authoruid)

    
       

       
        



        return{
            props,
            user,
            infoUserName, 
            timeAgo,



        }

     }



}
</script>

<style>

</style>