import { ref } from "vue";
import { db } from '@/firebase/config.js';
import {
    collection,
    getDocs,
    query,
    where,
    startAfter,
    orderBy,
    limit,
    endBefore,
    limitToLast,
    onSnapshot
  } from "firebase/firestore";
import getUser from '@/composables/getUser'






const getPosts = (uidGlobal) => {

    let total= 0
    let paginasInner = ref(0)
    let limite= ref(10)
    let pageInner = ref(1)
    let firstVis = ref("")
    var cargando = ref(false)

   
   


    var posts = ref([])
    const error = ref(null)



  
    const obtenerDatos = async () => {
      

        try{

          cargando.value = true 
          const q = query(collection(db, "posts"), where("status", "==", "public" ), orderBy("time", "desc"));

          const documentSnapshots = await getDocs(q);
          const totalDocumentos = documentSnapshots.docs.length;
          total = totalDocumentos;
          paginasInner.value = Math.ceil(total / limite.value);


          //console.log("total posts: " + total);
          //console.log("paginas: " + paginasInner.value);
          //console.log("_______________________")
          //console.log(limite);

          const data = await query(
            collection(db, "posts"),
            where("status", "==", "public" ), 
            orderBy("time", "desc"),
            limit(limite.value)
          );


          const querySnapshot = await onSnapshot(data, (querySnapshot) => {

            const AllPosts = [];
           
           
            
            querySnapshot.forEach((doc) => {
              let obra = doc.data();
              obra.id = doc.id;
  
              const user = getUser(obra.author_id)
              var url = user.user._value.photoURL
              var nameUser = user.user._value.displayName
  
              obra.UserUrl = url
              obra.UserName = nameUser            
              
  
             AllPosts.push(obra);
                
                

            });
            
            


            posts.value = AllPosts

            cargando.value = false

        });

          

        }
        catch(err){
            error.value = err.message
            console.log(error.value)
        }

        

      
    }


    const obtenerDatosCat = async (cat) => {
      

      try{
        cargando.value = true 
        const q = query(collection(db, "posts"), where("status", "==", "public" ), where("category", "==", cat ), orderBy("time", "desc"));
        const documentSnapshots = await getDocs(q);
        const totalDocumentos = documentSnapshots.docs.length;
        total = totalDocumentos;
        paginasInner.value = Math.ceil(total / limite.value);


        //console.log("total posts: " + total);
       // console.log("paginas: " + paginasInner.value);
        //console.log(limite);
        //console.log("_______________________")

        const data = await query(
          collection(db, "posts"),
          where("status", "==", "public" ), 
          where("category", "==", cat ),
          orderBy("time", "desc"),
          limit(limite.value)
        );


        const querySnapshot = await onSnapshot(data, (querySnapshot) => {

          const AllPosts = [];
         
         
          
          querySnapshot.forEach((doc) => {
            let obra = doc.data();
            obra.id = doc.id;

            const user = getUser(obra.author_id)
            var url = user.user._value.photoURL
            var nameUser = user.user._value.displayName

            obra.UserUrl = url
            obra.UserName = nameUser            
            

           AllPosts.push(obra);
              
              

          });
          
          


          posts.value = AllPosts
          cargando.value = false
      });

        

      }
      catch(err){
          error.value = err.message
          console.log(error.value)
      }

      

    
    }


    const obtenerDatosMine = async (uid) => {
      

      try{
        cargando.value = true 
        const q = query(collection(db, "posts"), where("status", "in", ["public", "draft","hidden"] ), where("author_id", "==", uid ), orderBy("time", "desc"));
        const documentSnapshots = await getDocs(q);
        const totalDocumentos = documentSnapshots.docs.length;
        total = totalDocumentos;
        paginasInner.value = Math.ceil(total / limite.value);


        //console.log("total posts: " + total);
        //console.log("paginas: " + paginasInner.value);
        //console.log(limite);
        //console.log("_______________________")

        const data = await query(
          collection(db, "posts"),
          where("status", "in", ["public", "draft","hidden"]),
          where("author_id", "==", uid ),
          orderBy("time", "desc"),
          limit(limite.value)
        );


        const querySnapshot = await onSnapshot(data, (querySnapshot) => {

          const AllPosts = [];
         
         
          
          querySnapshot.forEach((doc) => {
            let obra = doc.data();
            obra.id = doc.id;

            const user = getUser(obra.author_id)
            var url = user.user._value.photoURL
            var nameUser = user.user._value.displayName

            obra.UserUrl = url
            obra.UserName = nameUser            
            

           AllPosts.push(obra);
              
              

          });
          
          


          posts.value = AllPosts
          cargando.value = false
          
      });

      

      }
      catch(err){
          error.value = err.message
          console.log(error.value)
      }

      

    
    }


    const siguiente = async (page, filter) =>{

      cargando.value = true 
      // console.log(cargando)
      try{
      
        //console.log("siguiente:" + filter)


      if(filter == "all" && page !=1  ){



        //console.log("Entro All")
        const q = query(collection(db, "posts"), where("status", "==", "public" ), orderBy("time", "desc"));
        const documentSnapshots = await getDocs(q);

        var firstVisible = documentSnapshots.docs[limite.value * (page - 1) - 1] || null;
        firstVis = firstVisible

        const next = await query(
          collection(db, "posts"),
          where("status", "==", "public" ),
          orderBy("time", "desc"),
          limit(limite.value),
          startAfter(firstVis)
        );

        const querySnapshot = await getDocs(next);
      

          const AllPosts = [];
          querySnapshot.forEach((doc) => {
            let obra = doc.data();
            obra.id = doc.id;

            const user = getUser(obra.author_id)
            var url = user.user._value.photoURL
            var nameUser = user.user._value.displayName

            obra.UserUrl = url
            obra.UserName = nameUser  

            AllPosts.push(obra);

          });

          posts.value = AllPosts
          cargando.value = false
          
          // console.log(cargando)


      

      }

      if (page == 1 && filter == "all"){
        //console.log('Page 1 - All')
        obtenerDatos()
      }



      if(filter == "mine" && page !=1){
        
        //console.log('Entra Mine')
        const q = query(collection(db, "posts"), where("status", "in", ["public", "draft","hidden"]), where("author_id", "==", uidGlobal ), orderBy("time", "desc"));
        const documentSnapshots = await getDocs(q);

        var firstVisible = documentSnapshots.docs[limite.value * (page - 1) - 1] || null;
        firstVis = firstVisible

        const next = await query(
          collection(db, "posts"),
          where("status", "in", ["public", "draft","hidden"] ),
          where("author_id", "==", uidGlobal ), 
          orderBy("time", "desc"),
          limit(limite.value),
          startAfter(firstVis)
        );

       

        const querySnapshot = await getDocs(next);
      

          const AllPosts = [];
          querySnapshot.forEach((doc) => {
            let obra = doc.data();
            obra.id = doc.id;

            const user = getUser(obra.author_id)
            var url = user.user._value.photoURL
            var nameUser = user.user._value.displayName

            obra.UserUrl = url
            obra.UserName = nameUser  

            AllPosts.push(obra);

          });

          posts.value = AllPosts
          cargando.value = false

      }

      if(page == 1 && filter == "mine") {
        //console.log('Page 1 - Mine')
        obtenerDatosMine(uidGlobal)

      }

      
      if(filter != "mine" && filter != "all" && page !=1){
        
        //console.log('Entra Random Cat')
        const q = query(collection(db, "posts"), where("status", "==", "public" ), where("category", "==", filter ), orderBy("time", "desc"));
        const documentSnapshots = await getDocs(q);

        var firstVisible = documentSnapshots.docs[limite.value * (page - 1) - 1] || null;
        firstVis = firstVisible

        const next = await query(
          collection(db, "posts"),
          where("status", "==", "public" ),
          where("category", "==", filter ),
          orderBy("time", "desc"),
          limit(limite.value),
          startAfter(firstVis)
        );

       

        const querySnapshot = await getDocs(next);
      

          const AllPosts = [];
          querySnapshot.forEach((doc) => {
            let obra = doc.data();
            obra.id = doc.id;

            const user = getUser(obra.author_id)
            var url = user.user._value.photoURL
            var nameUser = user.user._value.displayName

            obra.UserUrl = url
            obra.UserName = nameUser  

            AllPosts.push(obra);

          });

          posts.value = AllPosts
          cargando.value = false
      }

      if(page ==1 && filter != "mine" && filter != "all"){
        //console.log('Page 1 - Random Cat')
        obtenerDatosCat(filter)
      }

      









      
     

     

      }
      catch(err){
          error.value = err.message
          console.log(error.value)
      }
      

}
    



  
      

    return { 
            posts,
            error,
            obtenerDatos,
            paginasInner,
            siguiente,
            pageInner,
            firstVis,
            obtenerDatosCat,
            obtenerDatosMine,
            cargando
           }

}

export default getPosts