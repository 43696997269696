import { ref } from "vue";
import { signOut } from "firebase/auth";
import { auth } from '@/firebase/config.js';

const error = ref(null)

const logout = async () =>{


    error.value = null

    try{
        const res = await signOut(auth).then(() => {
            // Sign-out successful.
          })
        error.value = null

    }

    catch(err){

        console.log(err.value)
        error.value = err.message


    }



}

const useLogout = () => {

    return { logout, error }

}

export default useLogout

