<template>

<v-navigation-drawer class="lg:hidden"
        v-model="catDown"
        location="bottom"
        temporary
      >
        <v-list class="p-0" density="compact">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :value="item"
          active-color="primary"
          :active="item.activese"
          @click="handleNav('forumFilter', item.filter)"
          
        >
        <div class="flex flex-row items-center gap-3 cursor-pointer  hover:text-[#64C3A5] ease-in-out duration-300 ">
          <v-icon :icon="item.icon" size="x-small" ></v-icon>
          <v-list-item-title v-text="item.text" class="text-sm"></v-list-item-title>
        </div>
    
        </v-list-item>
      </v-list>
</v-navigation-drawer>






    <div class="flex flex-col lg:flex-row lg:h-full">

      <!-- sub nav categories-->
      <div @click="catDown = !catDown" class="gap-2 w-full px-6 py-3 bg-slate-600 flex fixed z-[20] lg:hidden">

          <p class="grow uppercase tracking-[0.18em]">Categorias</p>
          <v-icon v-if="catDown == false" icon="mdi:mdi-chevron-down"></v-icon>
          <v-icon v-if="catDown == true" icon="mdi:mdi-close"></v-icon>

      </div>  

      <!-- sidebar -->
      <div class="hidden h-full lg:block lg:w-[280px] px-12 py-8 lg:px-0 lg:py-0 lg:bg-[#252b37] lg:border-r border-slate-600">
        <!-- Photo + User  -->
        <div class=" hidden flex items-center py-4 px-6 border-b border-slate-700">
          <div class="flex flex-row items-center gap-4">
            <v-avatar v-if="infoUserName.author_photoURL == ''" class="h-[40px] w-[40px] bg-primary">
              <p class="font-semibold text-lg"> {{ infoUserName.displayName.charAt(0) }} </p>
            </v-avatar>
            <v-avatar v-else class="h-[40px] w-[40px] ">
              <v-img  :src="infoUserName.author_photoURL"></v-img>
            </v-avatar>

            <p class="tracking-wider font-normal">{{ user.displayName }}</p>
          </div>
        </div>

      <div class="flex flex-col h-auto text-neutral-300">
        <!-- categories title -->
        <div
          class="
            px-4
            py-1.5
            flex flex-row
            items-center
            border-b border-slate-700
            bg-background
          "
        >
          <p
            class="
              uppercase
              tracking-[0.18em]
              text-xs
              font-semibold
              text-neutral-300
            "
          >
            Categories
          </p>
        </div>

        <!-- side menu -->
        <v-list class="p-0" density="compact">
          <v-list-item
            v-for="(item, i) in items"
            :key="i"
            :value="item"
            active-color="primary"
            :active="item.activese"
            @click="handleNav('forumFilter', item.filter)"
          >
            <div
              class="
                flex flex-row
                items-center
                gap-3
                cursor-pointer
                hover:text-[#64C3A5]
                ease-in-out
                duration-300
              "
            >
              <v-icon :icon="item.icon" size="x-small"></v-icon>
              <v-list-item-title
                v-text="item.text"
                class="text-sm"
              ></v-list-item-title>
            </div>
          </v-list-item>
        </v-list>
      </div>
      </div>
      
      <!-- content -->
      <div class="mt-[48px] px-5 sm:px-16 scroll1 lg:max-h-screen lg:overflow-y-scroll lg:mt-0 lg:bg-[#252b37] lg:px-15 py-8 rounded-md lg:w-[60%]">
        
        <v-form id="form-post" v-model="valid" @submit.prevent="handleSubmit()">

          <!-- header  -->
          <div class="flex items-center"> 
            <p class="grow text-sm card-title tracking-[0.30em] text-white w-1/2 sm:w-full grow ">CREATE POST / </p> 
            <v-btn color="primary" size="small">
                Publish
                <v-menu activator="parent">
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in menuMini"
                      :key="index"
                      :value="index"
                      @click="handleSubmit(item.state)"
                    >
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
            </v-btn>
          </div>
        
        
          <div class="flex flex-wrap gap-2 lg:flex-nowrap mt-5  lg:gap-5 ">
            <v-text-field class="w-[100%] lg:w-[50%] input-flv"
              v-model="title"
              :rules=" titleRules"
            
              label="Post Title"
              density="comfortable"
              color="bg-background"
              name="title"
    
              required
            ></v-text-field>

            <v-select class="w-[100%] lg:w-auto"
            v-model="categ"
              label="Category"
              density="comfortable"
              :items="['trades', 'markups', 'mindset', 'tutorials']"
              name="category"
              :rules="catRules"
              

            ></v-select>

          

          </div>

          <div id="editorjs" class="post-reader text-neutral-300  text-sm"></div>

      

            

        
        

        
          
  
    
        </v-form>
      
      </div>

    </div>

 

  <!-- The Modal -->
  <div id="modal" class="hidden fixed top-0 left-0 z-[2000] w-screen h-screen bg-black/70 flex justify-center items-center">
    <!-- The close button -->
    <a class="fixed z-90 top-6 right-8 text-white text-5xl font-bold" href="javascript:void(0)" onclick="closeModal()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
    </a>
    <!-- A big image will be displayed here -->
    <img id="modal-img" class="max-w-auto max-h-[90%] object-cover" />
  </div>

  <!-- snack bar -->
  <v-snackbar
      v-model="snackbar"
      :color="snackColor"
      :offset="50"
    
    >
      {{ textSnack }}

      <template v-slot:actions>
        <v-btn
          color=""
          variant="plain"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
  </v-snackbar>



</template>


<script>

//component 

import { onBeforeMount, onMounted, onUnmounted  } from 'vue'; 
import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
import EditorJS from '@editorjs/editorjs';

//composables
import SimpleImage from '@/composables/forum/simple-image.js'
import Mention from '@/composables/forum/editor/mention.js'
const Header = require("@editorjs/header")
import Underline from '@editorjs/underline'
const Marker = require('@editorjs/marker');
import List from '@editorjs/list';
const Checklist = require('@editorjs/checklist');
import Embed from '@editorjs/embed';
const Delimiter = require('@editorjs/delimiter');
import getUser from '@/composables/getUser'
import getInfoUser  from "@/composables/forum/getInfoUser"
import { getAuth, updateProfile } from "firebase/auth"
import handleNavMain from "@/composables/forum/handleNavMain"
import handleSnackGlobal from "@/composables/notifications/handleSnack"


//libraries
import { db } from '@/firebase/config.js';
import { getDoc, doc, setDoc, addDoc, collection } from "firebase/firestore"; 

export default {

components: {  },

setup(){

  const { handleNav } = handleNavMain()
  const { snackbar, textSnack, handleSnack, snackColor } = handleSnackGlobal()
  const router = useRouter()
  const { user } = getUser()
  const { loadInfoUser, infoUserName, loadInfoUser2, infoUserName2 } = getInfoUser()
  var uId = user.value.uid
  const catDown = ref(false)

  loadInfoUser(uId)

  const Header = require('@editorjs/header');

  const drawer2 = ref(true);
  let items = ref([
      {
        text: "All Posts",
        icon: "mdi:mdi-comment-multiple-outline",
        activese: false,
        filter: "all",
      },
      {
        text: "My Posts",
        icon: "mdi:mdi-folder-outline",
        activese: false,
        filter: "mine",
      },
      {
        text: "Trades",
        icon: "mdi:mdi-chart-box-outline",
        activese: false,
        filter: "trades",
      },
      // {
      //   text: "Markups",
      //   icon: "mdi:mdi-bookmark-outline",
      //   activese: false,
      //   filter: "markups",
      // },
      {
        text: "Mindset",
        icon: "mdi:mdi-fire",
        activese: false,
        filter: "mindset",
      },
      {
        text: "Tutorials",
        icon: "mdi:mdi-cellphone-link",
        activese: false,
        filter: "tutorials",
      },
    ]);

  let menuMini =ref([

        {text: "Publish",
        state: "public"},
        {
        text: "Save As Draft",
        state: "draft"
        },
        {
        text: "Publish hidden",
        state: "hidden"
        }
  ])

  const valid = ref(false);
  const title = ref('')
  const categ = ref('')
  const titleRules = [
        v => !!v || 'Title is required',
        //v => v.length <= 10 || 'Name must be less than 10 characters',
  ]
   const catRules = [
        v => !!v || 'Category is required',
        //v => v.length <= 10 || 'Name must be less than 10 characters',
  ]
  
  var body = ref('')
  var time = ref(null)

  const error = ref('')
  const statePost = ref('')
 



  



    const editor = new EditorJS({
            holder: "editorjs",
            initialBlock: "paragraph",
            readOnly: false,
            minHeight : 150,
            autofocus: true,
            tools: {
            
              image: SimpleImage,
              underline: Underline,
              embed: Embed,
              delimiter: Delimiter,
              header: {
                class: Header,
                config: {
                  placeholder: "Enter a header",
                  levels: [2, 3],
                  defaultLevel: 3,
                },
              },
              Marker: {
                class: Marker,
                shortcut: 'CMD+SHIFT+M',
              },
              list: {
                class: List,
                inlineToolbar: true,
                config: {
                  defaultStyle: 'unordered'
                }
              },
              checklist: {
                class: Checklist,
                inlineToolbar: true,
              },
              

      
            }
      
    });

  




  
  //Metodo para guardar post en Firebase

  const handleSubmit = async (statusPost) =>{

      try{

      let x = document.forms["form-post"]["title"].value;
      if (x == "") {
        //alert("Title post must be filled out");
        handleSnack("Title post must be filled out", "warning")
        return false;
      }

      let y = document.forms["form-post"]["category"].value;
      if (y == "") {
     
        handleSnack("A category post must be selected", "warning")
        return false;
      }

      const saveEdit = await editor.save().then( savedData=> {
        var x = JSON.stringify(savedData, null, 4);
        body = JSON.stringify(savedData.blocks, null, 4);
        time = savedData.time

      })

     

      if(body == '[]'){
            //console.log('Body is empty')
            handleSnack("Post can't be empty", "warning")
          }else{

            //createPost();
            const createPost = await addDoc(collection(db, "posts"), {
              title: title.value,
              category: categ.value,
              body: body,
              author_id: uId,
              status : statusPost,
              time: time
            })

            handleSnack("Your post was saved :) ", "success")
    

            router.push({ name: 'detailsPost', params:{id:createPost.id} })

              //console.log(createPost.id)
              



      }

      
      

      
      
  
      }

      catch(err){
          error.value = err.message
          console.log(error.value)
      }

  }



  onBeforeMount(() =>{
  
     
  } )

  
  onMounted(() => {

  })

  onUnmounted(() => {
    
    editor.destroy();

  })

  return{ 
          drawer2,
          user,
          items,
          valid,
          title,
          titleRules,
          categ,
          menuMini,
          body,
          statePost,
          handleSubmit,
          handleNav,
          snackbar,
          textSnack,
          handleSnack,
          snackColor,
          catRules,
          infoUserName,
          catDown

        }

}


}
</script>



  
<style scoped>




</style>