import { ref } from "vue";
import { getAuth,updateProfile,  createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '@/firebase/config.js';

const error = ref(null)
const loggedUser = ref(null)


const login = async (email, password) =>{

    

    error.value = null

    try{

        const res = await signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in 
          const user = userCredential.user;

          //console.log(user)
          // ...
        })

        //console.log(loggedUser.value)

        error.value = null




    }

    catch(err){

        console.log(err.value)
        error.value = 'Datos incorrectos'


    }




}

const useLogin = () => {

    return {error, login}

}

export default useLogin