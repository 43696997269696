<template>
  <form @submit.prevent="handleSubmit">
    <input class="w-full my-5 p-[10px] border-b-2 border-solid border-neutral-400" type="text" required placeholder="Display name" v-model="displayName">
    <input class="w-full my-5 p-[10px] border-b-2 border-solid border-neutral-400" type="email" required placeholder="Email" v-model="email">
    <input class="w-full my-5 p-[10px] border-b-2 border-solid border-neutral-400" type="password" required placeholder="Password" v-model="password">
    <p>{{ error }}</p>
    <v-btn @click="handleSubmit" class="text-[13px] w-full xl:text-[15px] h-[55px] rounded-[5px] tracking-[0.20em] shadow-none bg-primary">
  Registrarme
    </v-btn>
  </form>
</template>

<script>
import { ref } from 'vue'
import useSignup from '@/composables/useSignup.js'
export default {
  setup(props, context) {

      const { error, signup } = useSignup()
    // refs
    const displayName = ref('')
    const email = ref('')
    const password = ref('')





    const handleSubmit = async () => {
      await signup(email.value, password.value, displayName.value)
      if(!error.value){

        context.emit('signUp')
      }
    }

    return { displayName, email, password, handleSubmit, error}
  }
}
</script>

<style>
</style>