import { ref } from "vue";
import { collection, getDocs } from 'firebase/firestore'
import { doc, onSnapshot, query, where } from "firebase/firestore";
import { db } from '@/firebase/config.js';
import getUser from '@/composables/getUser'


const getSubPairsFiltered = (props,conditionN,valueN, conditionP, valueP) => {

    const subPairsFil = ref([])
    const errorSub = ref(null)

    const user = getUser()
    var uId = user.user._value.uid
    

  
    
    const loadSubPairsN = async () => {

        try{

        const q = query(collection(db, "pares_hijos"), where("papa", "==", props.pair.nombre + uId), where("powerHijoDad",conditionN,valueN));
        const paresCompletos = await onSnapshot(q, (querySnapshot) => {

            const paresHijos = [];
            
            
            //poderPapaResult2.push({ nombre: props.pair.nombre})
           
            
            querySnapshot.forEach((doc) => {

                paresHijos.push(doc.data());
                

            });


            subPairsFil.value = paresHijos
            //console.log(subPairsFil.value)

            
        });

        
        }

        catch(err){
            errorSub.value = err.message
            console.log(errorSub.value)
      }
    }









    const loadSubPairsP = async () => {

        try{

        const q = query(collection(db, "pares_hijos"), where("papa", "==", props.pair.nombre + uId), where("powerHijoDad",conditionP,valueP));
        const paresCompletos = await onSnapshot(q, (querySnapshot) => {

            const paresHijos = [];
            
            //poderPapaResult2.push({ nombre: props.pair.nombre})
           
            
            querySnapshot.forEach((doc) => {
                paresHijos.push(doc.data());
                

            });


            subPairsFil.value = paresHijos
            //console.log(subPairsFil.value)

            
        });

        
        }

        catch(err){
            errorSub.value = err.message
            console.log(errorSub.value)
      }
    }



























    // -------------------------------


    const loadSubPairsNv2 = async () => {

        try{

        const q = query(collection(db, "pares_hijos"), where("papa", "==", props.pair.nombre), where("powerHijoDadv2",conditionN,valueN));
        const paresCompletos = await onSnapshot(q, (querySnapshot) => {

            const paresHijos = [];
            
            //poderPapaResult2.push({ nombre: props.pair.nombre})
           
            
            querySnapshot.forEach((doc) => {
                paresHijos.push(doc.data());
                

            });


            subPairsFil.value = paresHijos

            
        });

        
        }

        catch(err){
            errorSub.value = err.message
            console.log(errorSub.value)
      }
    }

    const loadSubPairsPv2 = async () => {

        try{

        const q = query(collection(db, "pares_hijos"), where("papa", "==", props.pair.nombre), where("powerHijoDadv2",conditionP,valueP));
        const paresCompletos = await onSnapshot(q, (querySnapshot) => {

            const paresHijos = [];
            
            //poderPapaResult2.push({ nombre: props.pair.nombre})
           
            
            querySnapshot.forEach((doc) => {
                paresHijos.push(doc.data());
                

            });


            subPairsFil.value = paresHijos
            //console.log(subPairsFil.value)

            
        });

        
        }

        catch(err){
            errorSub.value = err.message
            console.log(errorSub.value)
      }
    }






    return { subPairsFil, errorSub, loadSubPairsN, loadSubPairsP, loadSubPairsNv2, loadSubPairsPv2}

}

export default getSubPairsFiltered