<template>

<div class=" grid gap-5 ">

    <div class=" gap-5 w-full  md:gap-10 ">

        <div class="bg-gradient-to-r from-green-600 p-1 md:w-8/12">
            <h1 class=" text-xs font-normal card-title  tracking-[0.15em] text-white ml-2"> STRONG </h1>
        </div>
        <div class="grid px-10 py-5 md:flex md:flex-wrap gap-16 xl:py-2">
            <div v-for="pair in PpairsFiltered" :key="pair">
                <SingleWeakv2 :pair="pair" />           
            </div>
        </div>
    </div>
    
    
    
    <div class=" gap-5 p-0 mt-5 w-full md:mt-0 md:gap-10 ">

        <div class="bg-gradient-to-r from-orange-600 p-1  md:w-8/12">
            <h1 class="text-xs font-normal card-title tracking-[0.25em] text-white ml-2"> WEAK</h1>
            <!-- <button @click="resetVariables()" class="btn"> reset variables </button> -->
        </div>
        
        
        <div class="grid px-10 py-5 md:flex md:flex-wrap gap-16  xl:py-2">
            <div v-for="pair in NpairsFiltered" :key="pair">
                <SingleWeakv2 :pair="pair" />            
            </div>
        </div>

    </div>

    
    


</div>


  
</template>

<script>

import getPairsWS from '@/composables/getWeakAndStrong'
import SingleWeakv2 from '@/components/groupPairs/SingleWv2.vue'
import { computed, ref } from '@vue/runtime-core'




export default {

components: { SingleWeakv2 },
setup(){

    
    //Get pairs 
    const { pairsP, pairsP_hidden, pairsN, pairsN_hidden, error, Nload, Nload_hidden, Pload} = getPairsWS("<=",-4,">=",4, "power" )
    
    Nload()
    Pload()

    const NpairsFiltered = computed(() => {
        return pairsN.value.sort((a, b) => (a.power > b.power) ? 1 : -1)    
    })

    const PpairsFiltered = computed(() => {
        return pairsP.value.sort((a, b) => (a.power > b.power) ? -1 : 1)    
    })

         
     


    return {

        NpairsFiltered,
        PpairsFiltered,
        pairsP,
        pairsP_hidden,
        pairsN,
        pairsN_hidden
        
    
        


    }
    }

}
</script>

<style>

</style>