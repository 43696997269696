import { createRouter, createWebHistory } from 'vue-router'
import Calificacion from '../views/calificacion.vue'
import Forum from '../views/forum.vue'
import CreatePost from '../views/CreatePost.vue'
import Dashboard from '../views/Dashboard.vue'
import Welcome from '../views/Welcome.vue'
import DetailsPost from '../views/DetailsPost.vue'
import { auth } from '@/firebase/config.js';


//auth Guard

const requireAuth = (to,from, next) =>{

  let user = auth.currentUser
  

  if(!user){
    next({name: 'welcome'})

  }else{
    next()
  }

}

const requireNoAuth = (to, from, next) =>{

  let user = auth.currentUser
  

  if(user){
    next({name: 'main'})

  }else{
    next()
  }

}


const routes = [
  {
    path: '/dashboard',
    name: 'main',
    component: Dashboard,
    beforeEnter: requireAuth
  },
  {
    path: '/calificacion',
    name: 'calificacion',
    component: Calificacion,
    beforeEnter: requireAuth
  },
  {
    path: '/',
    name: 'welcome',
    component: Welcome,
    beforeEnter: requireNoAuth
  },
  {
    path: '/forum',
    name: 'forum',
    component: Forum,
    beforeEnter: requireAuth
  },
  {
    path: '/create-post',
    name: 'createPost',
    component: CreatePost,
    beforeEnter: requireAuth
  },
  {
    path: '/posts/:id',
    name: 'detailsPost',
    component: DetailsPost, 
    props: true,
    beforeEnter: requireAuth
  },
  {
    path: '/forum/:filter',
    name: 'forumFilter',
    component: Forum, 
    props: true,
    beforeEnter: requireAuth
  }
  
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
