import { ref } from "vue";

const handleSnackGlobal = () =>{


    let snackbar = ref(false)
    let textSnack = ref('')
    let snackColor = ref('')
    

    const handleSnack = async (t, color) => {

        textSnack.value = t
        snackbar.value = true
        snackColor.value = color

    }

return{

    snackbar,
    textSnack,
    handleSnack,
    snackColor


}

}


export default handleSnackGlobal