<template>
  
      
    <div v-if="pair" class=" rounded-md  w-fit">


        <div class=" grid grid-col-12 p-0 flex-col gap-0  ">

            <div class=" flex flex-col   pt-0 pb-0 pl-0 align-middle ">
                
                <div v-if="(pair.power < '0')"  class="  flex items-center rounded-tl-md rounded-tr-md border-b-2 border-orange-600  h-10 pl-2 mb-0">

                    <span  class="  inline-block align-middle w-3 mr-2"><img  :src="getImg(pair.nombre)"  alt="">
                   
                    </span> 
                    <p class=" text-[13px]  card-title tracking-[0.14em]">{{ pair.nombre}}   <span class=" tracking-normal"> {{ pair.power }}</span>    </p>
                 </div>

                 <div v-if="(pair.power > '0')"  class="  flex items-center rounded-tl-md rounded-tr-md border-b-2 border-green-600  h-10 pl-2 mb-0">

                    <span  class="  inline-block align-middle w-3 mr-2"><img  :src="getImg(pair.nombre)"  alt="">
                   
                    </span> 
                    <p class=" text-[13px]  card-title tracking-[0.14em]">{{ pair.nombre}}   <span class=" tracking-normal"> {{ pair.power }}</span>    </p>
                 </div>

                <div class="p-0 bg-surface flex flex-col border-t border-l border-r   border-slate-700">      
                <div class="  h-[40px] flex items-center border-b border-slate-700" v-for="hijo, in pairsFiltered" :key="hijo">
                    <div v-if="(hijo.powerHijoDad == '-7') || (hijo.powerHijoDad == '-6')"
                        class="w-[18px] h-full bg-orange-600 flex items-center opacity-40">
                        <p class=" text-sm font-bold text-background w-full text-center ">{{ hijo.powerHijoDad}}</p>
                    </div>
                    <div v-if="(hijo.powerHijoDad == '-5')"
                        class="w-[18px] h-full bg-orange-500 flex items-center opacity-40">
                        <p class=" text-[13px] font-bold text-background w-full text-center">{{ hijo.powerHijoDad}}</p>
                    </div>
                    <div v-if="(hijo.powerHijoDad == '-4')"
                        class="w-[18px] h-full bg-orange-400 flex items-center opacity-40">
                        <p class=" text-[13px] font-bold text-background w-full text-center">{{ hijo.powerHijoDad}}</p>
                    </div>
                    
                    <div v-if="(hijo.powerHijoDad == '-3') "
                        class="w-[18px] h-full bg-orange-400 flex items-center">
                        <p class=" text-[13px] font-bold text-background w-full text-center">{{ hijo.powerHijoDad}}</p>
                    </div>

                    <div v-if="(hijo.powerHijoDad == '-2') || (hijo.powerHijoDad == '-1')"
                        class="w-[18px] h-full bg-orange-300 flex items-center">
                        <p class=" text-[13px] font-bold text-background w-full text-center">{{ hijo.powerHijoDad}}</p>
                    </div>
                    <div v-if="(hijo.powerHijoDad == '0')"
                        class="w-[18px] h-full bg-yellow-300 flex items-center">
                        <p class=" text-[13px] font-bold text-background w-full text-center">{{ hijo.powerHijoDad}}</p>
                    </div>
                     <div v-if="(hijo.powerHijoDad == '1') || (hijo.powerHijoDad == '2')"
                        class="w-[18px] h-full bg-green-300 flex items-center">
                        <p class=" text-[13px] font-bold text-background w-full text-center">{{ hijo.powerHijoDad}}</p>
                    </div>
                    <div v-if="(hijo.powerHijoDad == '3') "
                        class="w-[18px] h-full bg-green-400 flex items-center">
                        <p class=" text-[13px] font-bold text-background w-full text-center">{{ hijo.powerHijoDad}}</p>
                    </div>
                    <div v-if=" (hijo.powerHijoDad == '4') "
                        class="w-[18px] h-full bg-green-400 flex items-center opacity-40">
                        <p class=" text-[13px] font-bold text-background w-full text-center">{{ hijo.powerHijoDad}}</p>
                    </div>
                    <div v-if="(hijo.powerHijoDad == '5') "
                        class="w-[18px] h-full bg-green-500 flex items-center opacity-40">
                        <p class=" text-[13px] font-bold text-background w-full text-center">{{ hijo.powerHijoDad}}</p>
                    </div>
                    <div v-if="(hijo.powerHijoDad == '6') || (hijo.powerHijoDad == '7') "
                        class="w-[18px] h-full bg-green-600 flex items-center opacity-40">
                        <p class=" text-[13px] font-bold text-background w-full text-center">{{ hijo.powerHijoDad}}</p>
                    </div>
                    
                    <div class=" flex items-center gap-2  px-3 ">
                   
                  
                        

                       
                            <div v-if="hijo.fav === 'yes' ">
                    

                            <div @click="handleFav(hijo.id,hijo.id_inverso,hijo.fav)" class="w-4 h-4 border border-slate-500 flex align-middle justify-center">
                                <svg  @click="handleFav(hijo.id,hijo.id_inverso,hijo.fav)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-4 text-green-400">
                                    <path fill-rule="evenodd" d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z" clip-rule="evenodd" />
                                </svg>
                            </div>
                                

                            </div>

                            <div v-else>                
                                <div @click="handleFav(hijo.id,hijo.id_inverso,hijo.fav)" class="w-4 h-4 border border-slate-500 "></div>
                            </div>
                       



                        <p v-if="hijo.powerHijo != 0" class=" mr-2 text-[12px] tracking-[0.10em]"> {{ hijo.nombreCorr }} </p>
                        <p v-if="hijo.powerHijo === 0" class=" mr-2 text-[12px] tracking-[0.10em] opacity-40 "> {{ hijo.nombreCorr }} </p>

                    </div>

             


              
                    
                    
                </div> 

                

                

                <div >
                    
                </div>
            </div> 


                <!-- <div class="w-[62px] border-t border-l bg-surface font-normal  border-r border-slate-700 flex justify-center items-center "> <span class=" text-[13px] text-slate-500">Esc</span> </div> -->
            </div>

            

            
            
        </div>
    </div>


              
             
    
        
 
 
  

  

     


</template>

<script>

import getSubPairsFiltered from '@/composables/getSubPairsFiltered'
//import getPairsWS from '@/composables/getWeakAndStrong'
//import { ref } from '@vue/reactivity'
import { db } from '@/firebase/config.js'
import { doc, updateDoc, writeBatch, onSnapshot, query, where } from "firebase/firestore";
import { onMounted, onUpdated } from '@vue/runtime-core'
import { computed, ref } from '@vue/runtime-core'
import { BeakerIcon } from '@heroicons/vue/solid'




export default {
// emits:['sendPower'],
props: ['pair'],
component: { BeakerIcon },
setup(props){

    //traer subPares


    const { subPairsFil, errorSub, loadSubPairsN, loadSubPairsP} = getSubPairsFiltered(props,">=", -3,"<=",3  )
    //console.log(props.pair.power)
  
    if (props.pair.power <= -4){

        loadSubPairsN()

    }

    

    if (props.pair.power >= 4){
        loadSubPairsP()
    }
       

    //console.log(subPairsFil.value)
   

    // filtrar pares por par

    const pairsFiltered = computed(() => {

      

        
                return subPairsFil.value.sort((a, b) => (a.powerHijoDad > b.powerHijoDad) ? -1 : 1)
        
        
           
        })
    

 
    const powerValue = ref("")
    
    //Handle change of scenario 
    const handleChange = (id,id_hijo) =>{
        let selectValue = document.getElementById(id);
        let esc = selectValue.value

        //console.log(esc)
    

        updateDoc(doc(db, "pares_hijos", id), {  
        esc: esc          
        })

        updateDoc(doc(db, "pares_hijos", id_hijo), {  
        esc: esc          
        })


    }

    //Handle change of scenario 
    const handleFav = (id,id_hijo,fav) =>{
    

        
    
        if (fav === "no"){
            
            updateDoc(doc(db, "pares_hijos", id), {  
            fav: "yes"          
            })

            updateDoc(doc(db, "pares_hijos", id_hijo), {  
            fav: "yes"        
            })

        } else {

            updateDoc(doc(db, "pares_hijos", id), {  
            fav: "no"          
            })

            updateDoc(doc(db, "pares_hijos", id_hijo), {  
            fav: "no"        
            })

        }
        
        

       


    }

    //handle chane of Cheese variable
    const handleChange2 = (id,id_hijo,field) =>{

        let selectValue = document.getElementById(id + field);
        let v_value = selectValue.value
        

        //console.log(field)
    
        if(field === 'pri_vela' ){
            updateDoc(doc(db, "pares_hijos", id), {  
                pri_vela : v_value          
            })
            updateDoc(doc(db, "pares_hijos", id_hijo), {  
                pri_vela : v_value          
            })
        }


        if(field === 'brusquito' ){

            updateDoc(doc(db, "pares_hijos", id), {  
                brusquito : v_value          
            })
            updateDoc(doc(db, "pares_hijos", id_hijo), {  
                brusquito : v_value          
            })
        }


        if(field === 'ob_os' ){
            updateDoc(doc(db, "pares_hijos", id), {  
                ob_os : v_value          
            })
            updateDoc(doc(db, "pares_hijos", id_hijo), {  
                ob_os : v_value          
            })
        }

        if(field === 'ad_es' ){
            updateDoc(doc(db, "pares_hijos", id), {  
                ad_es : v_value          
            })
            updateDoc(doc(db, "pares_hijos", id_hijo), {  
                ad_es : v_value          
            })
        }

        if(field === 'esc_inicio' ){
            updateDoc(doc(db, "pares_hijos", id), {  
                esc_inicio : v_value          
            })
            updateDoc(doc(db, "pares_hijos", id_hijo), {  
                esc_inicio : v_value          
            })
        }

        if(field === 'esc_canal' ){
            updateDoc(doc(db, "pares_hijos", id), {  
                esc_canal : v_value          
            })
            updateDoc(doc(db, "pares_hijos", id_hijo), {  
                esc_canal : v_value          
            })
        }

        if(field === 'esc_emas' ){
            updateDoc(doc(db, "pares_hijos", id), {  
                esc_emas : v_value          
            })
            updateDoc(doc(db, "pares_hijos", id_hijo), {  
                esc_emas : v_value          
            })
        }

        if(field === 'esc_ob_os'){
            updateDoc(doc(db, "pares_hijos", id), {  
                esc_ob_os : v_value          
            })
            updateDoc(doc(db, "pares_hijos", id_hijo), {  
                esc_ob_os : v_value          
            })
        }



    }

    //Obtener imagen flag de cada par
    const getImg = (img) =>{
        
        var imagesFlag = require.context('../../assets', false, /\.png$/)
        return imagesFlag('./' + img + ".png")
    }

    

        
      


        return { 
            
            subPairsFil,
            options: ["PB | 1H", "PB | 4H", "PB | D", "Cheese", "Eclipse", " Ra | 1H " , "Ra | 4H" , "Ra | D" , "Ch | 1H", "Ch | 4H", "Ch | D",  "Ind | D","Re | 1H", "Re | 4H","Re | D","Megh", "" ],
            optionsYS:["Yes","No", ""],
            handleChange,
            getImg,
            powerValue,
            pairsFiltered,
            handleChange2,
            handleFav

            
      
          
           
            
            
            // updatePairs
        }

    }

}
</script>

<style>

.esceEspe{
    height: 40px;
}

.toltipandera{
    left: 105% !important;
    top: 13px !important;
    bottom: auto !important;
    transform-origin: left !important;

}



</style>