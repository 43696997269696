<template>
  <div v-if="(visibleNav == true)" class="drawer-side">
    <label for="my-drawer-3" class="drawer-overlay"></label> 
    <ul class="menu p-5 overflow-y-auto w-80 bg-base-100">


      <div class="flex w-full mb-5 items-center justify-center border-b border-slate-700 pb-3">
       
          <img class="w-40" src="../assets/logo.png"/>
      
          <div class="flex-grow"></div>
          
     <div @click="toggleNav">  
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
     </div>
       
      </div>


      <!-- Sidebar content here -->
      <router-link :to="{ name: 'main'}">
        <li @click="toggleNav"  class="">
          <a>
            
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" /></svg>
              <p class="tracking-[0.1em] text-sm">DASHBOARD</p>
            
          </a> 
        </li>
      </router-link>
  
      <router-link :to="{ name: 'calificacion'}">
        <li @click="toggleNav" class="">
          <a>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
            </svg>
            <p class="tracking-[0.1em] text-sm">QUALIFY</p>
          </a>
        </li>
      </router-link>
      
      <li @click="toggleNav" class="">
            <a href="https://comunidad.folleselavida.com/" target="_blank" >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
              </svg>
              <p class="tracking-[0.1em] text-sm">COMMUNITY</p>
            </a>
            
      </li>

        <span class="font-bold text-lg">{{ user.displayName }}</span>
        <span class="text-info">{{ user.email }}</span>
      
      <button @click="handleClick" class="btn  btn-sm btn-primary btn-block">SIGN OUT</button>
    </ul>

    
    
  </div>
</template>

<script>
import useLogout from '@/composables/useLogout'
import getUser from '@/composables/getUser'
import { useRouter } from 'vue-router'
import { ref, watch } from '@vue/runtime-core'


export default {

    setup(){

        const visibleNav = ref();

        const {logout, error } = useLogout()
        const { user } = getUser()

        watch(user, () =>{

        if(user.value){
            
            visibleNav.value = true
            //console.log('user true', visibleNav.value)


        }else{
            visibleNav.value = false
            //console.log('user true', visibleNav.value)
        }


        })

        const router = useRouter()
        const handleClick = async () =>{

            await logout()
            if(!error.value){
                visibleNav.value = false
                router.push({name: 'welcome'})
            }

        }

        const toggleNav = () =>{

          var checkbox = document.getElementById('my-drawer-3');
          checkbox.checked = !checkbox.checked;

        }


    return{

        toggleNav,
        handleClick,
        visibleNav,
        user
    }

    }

}
</script>

<style>

</style>