<template>



  <div class=" flex flex-col gap-10 items-center justify-center px-5 py-10 xl:h-screen  xl:px-15">

    <div class="flex flex-col gap-5 items-center justify-center lg:w-[30%] text-center">
      <img class="w-60 lg:w-80" src="@/assets/Logo-FLV.png"/>
      <p class=" ml-2 text-xl lg:text-xl justify-center">Ponle fin a cambiar de estrategia.<br> Aprende a <span class="font-bold">enfocarte</span>, ser <span class="font-bold">rentable</span> y vivir de operar Forex.</p>
    </div>

    <div class="bg-surface px-8 lg:px-15 py-10  rounded-[15px] lg:w-[35%]">

      <div v-if="showLogin">
        <h3>Iniciar Sesión</h3>
        <LoginForm @login="enterDash" />
        <!-- <p class="mt-5">¿Aún no tienes cuenta? <span class="cursor-pointer underline font-bold " @click="showLogin = false">Regístrate</span></p> -->
      </div>
      <!-- <div v-else>
        <h3>Registro</h3>
        <SignupForm @signUp="enterDash" />
        <p class="mt-5">¿Ya estás registrado? <span class="cursor-pointer underline font-bold" @click="showLogin = true">Inicia sesión</span></p>
      </div> -->
    </div>
  
  </div>

  


  

</template>

<script>
import SignupForm from '../components/SignupForm.vue'
import LoginForm from '../components/LoginForm.vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
export default {
  components: { SignupForm, LoginForm },
  setup() {
    const showLogin = ref(true)
    const router = useRouter()

    const enterDash = () => {

        router.push({name: 'main'})

    }

   




    return {    showLogin, 
                enterDash }
  }
}
</script>

<style>
 
  /* form styles */


  


</style>