<template>

<v-navigation-drawer
        v-model="catDown"
        location="bottom"
        temporary
      >
        <v-list class="p-0" density="compact">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :value="item"
          active-color="primary"
          :active="item.activese"
          @click="filterState(item.filter)"
          
        >
        <div class="flex flex-row items-center gap-3 cursor-pointer  hover:text-[#64C3A5] ease-in-out duration-300 ">
          <v-icon :icon="item.icon" size="x-small" ></v-icon>
          <v-list-item-title v-text="item.text" class="text-sm"></v-list-item-title>
        </div>
    
        </v-list-item>
      </v-list>
</v-navigation-drawer>

<div class="flex flex-col lg:flex-row lg:h-full">

      <!-- categories nav mobile -->
      <div @click="catDown = !catDown" class="gap-2 w-full px-6 py-3 bg-slate-600 flex fixed z-[20] lg:hidden">

        <p class="grow uppercase tracking-[0.18em]">Categories</p>
        <v-icon v-if="catDown == false" icon="mdi:mdi-chevron-down"></v-icon>
        <v-icon v-if="catDown == true" icon="mdi:mdi-close"></v-icon>

      </div>
      <!-- side bar -->
      <div class="hidden h-full lg:block lg:w-[280px] px-12 py-8 lg:px-0 lg:py-0 lg:bg-[#252b37] lg:border-r border-slate-600">
          <!-- Photo + User  -->
    <div class="hidden flex items-center py-4 px-6 border-b border-slate-700">
      <div class="flex flex-row items-center gap-4">
        <v-avatar v-if="infoUserName.author_photoURL == ''" class="h-[40px] w-[40px] bg-primary">
          <p class="font-semibold text-lg"> {{ infoUserName.displayName.charAt(0) }} </p>
        </v-avatar>
        <v-avatar v-else class="h-[40px] w-[40px] ">
          <v-img  :src="infoUserName.author_photoURL"></v-img>
        </v-avatar>

        <p class=" tracking-wider font-normal">{{ user.displayName}}</p>
   
        
      </div>
    </div>

    <div class=" flex flex-col h-auto text-neutral-300">
  
      <!-- categories title -->
      <div class="px-4 py-1.5 flex flex-row items-center border-b border-slate-700 bg-background">
        
        <p  class=" uppercase tracking-[0.18em] text-xs font-semibold text-neutral-300"> Categories </p>

      </div>

      <!-- side menu -->
      <v-list class="p-0" density="compact">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :value="item"
          active-color="primary"
          :active="item.activese"
          @click="filterState(item.filter)"
          
        >
        <div class="flex flex-row items-center gap-3 cursor-pointer  hover:text-[#64C3A5] ease-in-out duration-300 ">
          <v-icon :icon="item.icon" size="x-small" ></v-icon>
          <v-list-item-title v-text="item.text" class="text-sm"></v-list-item-title>
        </div>
    
        </v-list-item>
      </v-list>
      
      
    </div>

      </div> 

      <!-- Content -->
      <div class=" mt-15 lg:mt-0 w-auto grow scroll1 lg:overflow-y-scroll lg:h-[100%] lg:p-5">
        <div class="lg:bg-[#252b37] p-6 rounded-md lg:w-[70%]">
          <!-- title + button -->
          <div class="flex items-center">
              <div class="grow">
              <div v-for="
                (item, i) in items"
                :key="i" 
                class="flex gap-3 items-center text-neutral-400">
                <v-icon v-if="filterGlobal == item.filter" :icon="item.icon" size="small"></v-icon>
                <p v-if="filterGlobal == item.filter"  class=" uppercase text-lg card-title tracking-[0.30em] w-1/2 sm:w-full "> {{ item.text }} </p>
              </div>
              </div>

              <v-btn 
              class=" text-[13px] xl:text-[11px]  rounded-[5px] tracking-[0.20em] shadow-none bg-primary"
              @click="handleNav('createPost')"
              >
              <p> Create New </p> 
              </v-btn>
          </div>

          <div v-if="(posts.length) && (cargando == false)"  class="flex flex-col gap-3 mt-5">

            <!-- post for -->
            <div v-for="post in posts" :key="post.id" >

              <SinglePost :post="post" :filter="filterGlobal"  />

              <v-divider class="text-neutral-200 border-slate-700 mt-4"></v-divider>

            </div>

            <!-- pagination -->
            <div class="text-center">
              <v-pagination class="mt-5 text-xs pagination-flv text-neutral-400"
                v-model="page"
                :length="paginas"
                rounded="circle"
                :filter="filterGlobal"
                @click="siguiente(page, filterGlobal)"
                total-visible="5"
              ></v-pagination>
            </div>

          </div>

          <!-- spinner waiting for posts -->
          <div v-else class="w-full text-center p-10">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>

        </div>

        

      </div>


</div>



</template>



<script>

//composables 
import getPosts from '@/composables/forum/getPosts.js'
import getTimeAgo from '@/composables/forum/timeAgo'
import handleNavMain from '@/composables/forum/handleNavMain'
import getUser from '@/composables/getUser'
import getInfoUser  from "@/composables/forum/getInfoUser"


//imports vue 
import { ref, computed, reactive } from "vue";
import { onBeforeMount, onMounted, onUpdated } from '@vue/runtime-core';


//imports Firebase
import { getAuth, updateProfile } from "firebase/auth"


import SinglePost from '@/components/forum/singlePost.vue'









export default {
 
  data(){
        
    return{

          
        }
    },

  props: ["filter"],
  components: { SinglePost },
  setup(props) {

      //get domposables functions
      const { user } = getUser()
      const auth = getAuth()
      const { timeAgo } = getTimeAgo()
      const { handleNav } = handleNavMain()
      const { 
        posts,
        error,
        obtenerDatos,
        paginasInner,
        siguiente,
        pageInner,
        totalInner,
        firstVis,
        obtenerDatosCat,
        obtenerDatosMine,
        cargando
      } = getPosts(user.value.uid)

      const { loadInfoUser, infoUserName } = getInfoUser()
      const catDown = ref(false)

      //console.log(cargando.value)


      //declaring variables

      
      const drawer2 = ref(true)
      let items = ref([
            { text: 'All Posts', icon: 'mdi:mdi-comment-multiple-outline', activese: true, filter: "all" },
            { text: 'My Posts', icon: 'mdi:mdi-folder-outline', activese: false, filter: "mine" },
            { text: 'Trades', icon: 'mdi:mdi-chart-box-outline', activese: false, filter: "trades" },
            //{ text: 'Markups', icon: 'mdi:mdi-bookmark-outline', activese: false, filter: "markups" },
            { text: 'Mindset', icon: 'mdi:mdi-fire', activese: false, filter: "mindset" },
            { text: 'Tutorials', icon: 'mdi:mdi-cellphone-link', activese: false, filter: "tutorials" }
          
          ])

      let paginas = paginasInner
      let page = pageInner
      let total = totalInner
      let Fvis = firstVis
      let filterGlobal = ref("all")  
   

      onMounted(() => {

        
     


        if(!props.filter){
          //console.log("no hay tag")
          obtenerDatos()
        }
        else{
          //console.log(props.filter)

          filterState(props.filter)
        }

        

      })

   
      loadInfoUser(user.value.uid)

     

     



      //custom function

      const filterState = (f) =>{
 
        var itemActivo = items.value.find(item => item.activese === true)
        var nextItem = items.value.find(item => item.filter === f)


        //console.log("Item Activo:" + itemActivo.filter);
       // console.log("Next Item:" + nextItem.filter);

        itemActivo.activese = false
        nextItem.activese = true
        

        filterGlobal.value = f
        //console.log("Filtro: " + filterGlobal.value )
        pageInner.value = 1

        if(catDown.value == true){
          catDown.value = false;
        }

        if(f == "all"){

            obtenerDatos()

        } else if (f =="mine") {

          //console.log(user.value.uid)
          obtenerDatosMine(user.value.uid)


        } else {
        
        obtenerDatosCat(f)
        
        }
      }

      const updatePhotoURL = ()=>{

          console.log("entra update")

                  updateProfile(auth.currentUser, {
              displayName: "Plopfox", photoURL: "https://pbs.twimg.com/profile_images/1259828810641530881/JkveSyQa_400x400.jpg"
            }).then(() => {
              // Profile updated!

              console.log("plop")
              // ...
            }).catch((error) => {
              // An error occurred
              // ...
            });

      }




      return{
        drawer2,
        updatePhotoURL,
        user,
        paginas, 
        posts,
        timeAgo,
        siguiente,
        page,
        total,
        filterState,
        filterGlobal,
        items,
        handleNav,
        SinglePost,
        infoUserName,
        cargando,
        catDown

    
     

      }


    
  }
}
</script>

<style >



.pagination-flv button{

width: 35px !important;
height: 35px !important;
}

.pagination-flv .v-icon{

font-size: 0.8rem;

}



</style>