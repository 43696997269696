import { ref } from "vue";
//firebase
import { getDoc, doc, updateDoc, onSnapshot, query, where, addDoc, collection } from "firebase/firestore"
import { db } from '@/firebase/config.js'
import { getAuth, updateProfile } from "firebase/auth"

import getUser from '@/composables/getUser'


import {  } from "firebase/firestore"; 

//editor
import EditorJS from "@editorjs/editorjs"
import SimpleImage from "@/composables/forum/simple-image.js"
const Header = require("@editorjs/header")
import Underline from '@editorjs/underline'
const Marker = require('@editorjs/marker');
import List from '@editorjs/list';
const Checklist = require('@editorjs/checklist');
import Embed from '@editorjs/embed';
const Delimiter = require('@editorjs/delimiter');
const Paragraph = require('@editorjs/paragraph');






const getEditor = () =>{

    const user = getUser()
    var u_id = user.user.value.uid
    var savedUser = ref(user.user)
    

    var editorMain = null
    var editorComment = null
    let postGlobal = ref([])
    let blocks = ref(null)
    const error = ref(null)
    let editMode =ref(false)
    let snackbar = ref(false)
    let textSnack = ref('')
 
    
    
    const loadEditorMain = async (readMode, post_id, loader_id) =>{
        
        editorMain = new EditorJS({
            holder: loader_id,
            initialBlock: "paragraph",
            defaultBlock:"paragraph",
            readOnly: readMode,
            autofocus: true,
            minHeight : "80",
            tools: {
              paragraph: {
                class: Paragraph,
                inlineToolbar: true,
                preserveBlank: true,
              },
              image: SimpleImage,
              underline: Underline,
              embed: Embed,
              delimiter: Delimiter,
              header: {
                class: Header,
                config: {
                  placeholder: "Enter a header",
                  levels: [2, 3],
                  defaultLevel: 3,
                },
              },
              Marker: {
                class: Marker,
                shortcut: 'CMD+SHIFT+M',
              },
              list: {
                class: List,
                inlineToolbar: true,
                config: {
                  defaultStyle: 'unordered'
                }
              },
              checklist: {
                class: Checklist,
                inlineToolbar: true,
              },
              

      
            }
      
        });

        editorMain.isReady.then(() => {
            loadPost(post_id)
            //console.log(post_id)
        })



    }

    const loadEditorComment = async (readMode, post_id, loader_id) =>{
        
      editorComment = new EditorJS({
          holder: loader_id,
          initialBlock: "paragraph",
          defaultBlock:"paragraph",
          readOnly: readMode,
          minHeight : 80,
          autofocus: true,
          tools: {
            paragraph: {
              class: Paragraph,
              inlineToolbar: true,
              preserveBlank: true,
            },
            image: SimpleImage,
            underline: Underline,
            embed: Embed,
            delimiter: Delimiter,
            header: {
              class: Header,
              config: {
                placeholder: "Enter a header",
                levels: [2, 3],
                defaultLevel: 3,
              },
            },
            Marker: {
              class: Marker,
              shortcut: 'CMD+SHIFT+M',
            },
            list: {
              class: List,
              inlineToolbar: true,
              config: {
                defaultStyle: 'unordered'
              }
            },
            checklist: {
              class: Checklist,
              inlineToolbar: true,
            },
            

    
          }
    
      });

      


  }

    const loadPost = async (post_id) => {

        const docRef = doc(db, "posts", post_id);

        try{
            const docSnap = await getDoc(docRef);

            if(docSnap.exists()) {

                

                var x = docSnap.data()

                const user = getUser(x.author_id)
                var url = user.user._value.photoURL
                var nameUser = user.user._value.displayName
                //var blocks = JSON.parse(x.body)
                //console.log(x.body)
                x.UserUrl = url
                x.UserName = nameUser  
                
                
                postGlobal.value = x 
                blocks = x.body
                editorMain.blocks.render({ blocks: JSON.parse(x.body) });
                
               //console.log(postGlobal.value);

            } else {
                console.log("Document does not exist")
            }

            
        
        }

        catch(err){
           
            error.value = err.message
            console.log(error.value)
            handleSnack(error.value)
      }
    }

    const handleEdit = async () => {

        editorMain.readOnly.toggle()
        editMode.value = !editMode.value 
        
        //console.log(editMode.value)

    }

    const saveEdit = async (post_id) => {

      var body = ref('')

      try {

        const saveEdit = await editorMain.save().then( savedData=> {
          var x = JSON.stringify(savedData, null, 4);
          body = JSON.stringify(savedData.blocks, null, 4);
          //time = savedData.time
          console.log(body)
          
          if(body == '[]'){
            console.log('Body is empty')
            editorMain.blocks.insert('paragraph', {text:"Post can't be empty"} )
          }else{

          
        
              updateDoc(doc(db, "posts", post_id), {  
                body: body     
              })
      
              handleEdit()
              handleSnack("Your post was saved :) ")

          }


        })

        
        


      } catch (err) {
            error.value = err.message
            console.log(error.value)
        
      }

      




    }

    const createComment = async (post_id) => {

      var body = ref('')

      try {

        const saveEdit = await editorComment.save().then( savedData=> {
          var x = JSON.stringify(savedData, null, 4);
          body = JSON.stringify(savedData.blocks, null, 4);
          var time = savedData.time
       
          
          if(body == '[]'){
            handleSnack("Comment can´t be empty ")
          }else{


              const createPost = async () => { await addDoc(collection(db, "posts"), {

     
                body: body,
                author_id: u_id,
                time: time, 
              
                author_id: u_id,
               
            
                parent: post_id


              })
      
            }
              createPost();
              editorComment.clear()
              handleSnack("Your comment is published :) ")

          }


        })

        
        


      } catch (err) {
            error.value = err.message
            console.log(error.value)
        
      }

      




    }


    const handleSnack = async (t) => {

      textSnack.value = t
      snackbar.value = true



    }

    const destroyEditor = async () => {

      editorMain.destroy();
      editorComment.destroy();



    }




    

    return { 
      loadEditorMain,
      loadEditorComment,
      postGlobal,
      handleEdit, 
      editMode, 
      saveEdit, 
      snackbar, 
      textSnack, 
      handleSnack,
      createComment,
      destroyEditor

    }

}

export default getEditor