<template>
  
      
    <div v-if="pair" class="mainQualify rounded-md bg-surface text-primary-content">
        <div>
            <div class="flex p-0 flex-col card-body gap-0 ">
                <div class=" flex items-center rounded-tl-md rounded-tr-md border-b border-slate-700 pt-2 pb-2 pl-4 align-middle ">
                    <span  class="inline-block align-middle w-4 mr-3"><img  :src="getImg(pair.nombre)"  alt="">
                    <!-- <img src="../assets/logo.png"  alt="" class="" > -->
                    </span> 
                    <p class=" text-sm card-title tracking-[0.15em]">{{ pair.nombre}}   <span :powervalue="poderPapaResult" :id="pair.nombre + '_power'" class="ml-4 tracking-normal">{{ poderPapaResult }}</span>    </p> 
                </div>
                
                <div class="p-2 mr-1 ml-1">      
                    <div v-for="hijo in subPairs" :key="hijo">
                        <div v-if="(hijo.especial == 'yes')" class="subpairCal pt-1.5 pb-1.5">
                            <p class="mr-2 text-[12px] tracking-[0.10em] grow">{{ hijo.nombreCorr }}</p>


                            <select v-if="(hijo.especial == 'yes') && (hijo.powerHijo != '0')" class=" h-8 rounded-md border border-slate-600 bg-background pl-5 pr-5 text-[13px]  " @change="handleChange(hijo.id,hijo.id_inverso)"  :value="hijo.powerHijo" :id="hijo.id+1">
                                <option v-for="option in options" :key="option">
                                    {{  option }}
                                </option>
                            </select>

                            <select v-if="(hijo.especial == 'yes') && (hijo.powerHijo == '0') " class="h-8 rounded-md border bg-background border-slate-400 border-dashed pl-5 pr-5 text-[13px]"  @change="handleChange(hijo.id,hijo.id_inverso)"  :value="hijo.powerHijo"  :id="hijo.id+1">
                                <option v-for="option in options" :key="option">
                                    {{  option }}
                                </option>
                            </select>

                           
                        </div>
                        
                        <!-- <div v-if="(hijo.especial != 'yes')" class="subpairCal pt-1.5 pb-1.5 hidden">

                                <select class="h-8 rounded-md border border-slate-600 bg-background pl-5 pr-5 text-[13px] " disabled @change="handleChange(hijo.id,hijo.id_inverso)"  :value="hijo.powerHijo" :placeholder="hijo.nombreCorr" :id="hijo.id">
                                <option v-for="option in options" :key="option">
                                    {{  option }}
                                </option>
                            </select>
                        </div>  -->


                        
                    </div> 
                </div> 
            </div>
        </div>


        


    </div>


              
             
    
        
 
 
  

  

     


</template>

<script>

import getSubPairs from '@/composables/getSubPairs'
import { ref } from '@vue/reactivity'
import { db } from '@/firebase/config.js';
import { doc, updateDoc, writeBatch } from "firebase/firestore"; 
import { onMounted, onUpdated } from '@vue/runtime-core';


export default {
emits:['sendPower'],
props: ['pair'],
component: {  },
setup(props, context){

    

     //Get Sub Pairs
    
    const { subPairs, errorSub, loadSubPairs, poderPapaResult} = getSubPairs(props)
    loadSubPairs()

    const powerValue = ref("")

    
    


        const handleChange = (id,id_hijo) =>{
            let selectValue = document.getElementById(id+1);
            let poderHijoNumber = parseInt(selectValue.value) 

            
            

            updateDoc(doc(db, "pares_hijos", id), {  
            powerHijo: poderHijoNumber          
            })


            if( poderHijoNumber === -1){
                updateDoc(doc(db, "pares_hijos", id_hijo), {  
                powerHijo: 1        
                })
                
            }
            if( poderHijoNumber === 1){
                updateDoc(doc(db, "pares_hijos", id_hijo), {  
                powerHijo: -1       
                })
               
            }
            if( poderHijoNumber === 0){

                updateDoc(doc(db, "pares_hijos", id_hijo), {  
                powerHijo: 0       
                })
                

            }

        }

        //Obtener imagen flag de cada par
        const getImg = (img) =>{
           
            var imagesFlag = require.context('../../assets', false, /\.png$/)
            return imagesFlag('./' + img + ".png")
        }

        
        
        onUpdated(() => {
            

        })


      
     
        


        return { 
            
            subPairs,
            options: [0,-1, 1],
            handleChange,
            poderPapaResult,
            getImg,
            powerValue
            
            
            // updatePairs
            
  
        }


        

    }

}
</script>

<style>

.subpairCal{

    display: flex;
    align-items: center;
}

.subpairCal p{

    flex-grow: 1 !important;
}

.inputPair .v-input__control,
.inputPair .v-field__field,
.inputPair .v-field__input{
    height: 32px !important;
   

}

.inputPair .v-select__selection{
    justify-content: center;
    font-weight: 300 !important;
    font-size: 14px;
}


.inputPair .v-field__input{
    padding: 0px;
    min-height: 32px !important;
    align-items: center;
    

}

.inputPair .v-select__selection{
    width: 100%;
}








</style>