import { ref } from "vue";
import { db } from '@/firebase/config.js';
import {
    collection,
    getDocs,
    query,
    where,
    startAfter,
    orderBy,
    limit,
    endBefore,
    limitToLast,
    onSnapshot
  } from "firebase/firestore";
import getUser from '@/composables/getUser'

const getComments = () => {

    let total= 0
    let paginasInner = ref(0)
    let limite= ref(30)
    let pageInner = ref(1)
    let firstVis = ref("")

   
    var posts = ref([])


    var posts = ref([])
    const error = ref(null)

    
    const obtenerComments = async (parentId) => {
      

        try{
          const q = query(collection(db, "posts"), where("parent", "==", parentId ), orderBy("time"));

          const documentSnapshots = await getDocs(q);
          const totalDocumentos = documentSnapshots.docs.length;
          total = totalDocumentos;
          paginasInner.value = Math.ceil(total / limite.value);


          //console.log("total posts: " + total);
          //console.log("paginas: " + paginasInner.value);
          //console.log("_______________________")
          //console.log(limite);

          const data = await query(
            collection(db, "posts"),
            where("parent", "==", parentId ), 
            orderBy("time"),
            limit(limite.value)
          );


          const querySnapshot = await onSnapshot(data, (querySnapshot) => {

            const AllPosts = [];
           
           
            
            querySnapshot.forEach((doc) => {
              let obra = doc.data();
              obra.id = doc.id;
          
              
  
             AllPosts.push(obra);
                
                

            });
            
            


            posts.value = AllPosts

        });

          

        }
        catch(err){
            error.value = err.message
            console.log(error.value)
        }

        

      
    }

    const siguiente = async (page, parentId) =>{


        try{
        
          console.log(parentId)
  
        if( page !=1  ){
  
          //console.log("Entro All")
          const q = query(collection(db, "posts"),  where("parent", "==", parentId ), orderBy("time", "desc"));
          const documentSnapshots = await getDocs(q);
  
          var firstVisible = documentSnapshots.docs[limite.value * (page - 1) - 1] || null;
          firstVis = firstVisible
  
          const next = await query(
            collection(db, "posts"),
            where("parent", "==", parentId ),
            orderBy("time", "desc"),
            limit(limite.value),
            startAfter(firstVis)
          );
  
          const querySnapshot = await getDocs(next);
        
  
            const AllPosts = [];
            querySnapshot.forEach((doc) => {
              let obra = doc.data();
              obra.id = doc.id;
  
              const user = getUser(obra.author_id)
              var url = user.user._value.photoURL
              var nameUser = user.user._value.displayName
  
              obra.UserUrl = url
              obra.UserName = nameUser  
  
              AllPosts.push(obra);
  
            });
  
            posts.value = AllPosts
  
        
  
        }
  
        if (page == 1){
          //console.log('Page 1 - All')
          obtenerComments(parentId)
        }
  
  
        
    
  
  
        }
        catch(err){
            error.value = err.message
            console.log(error.value)
        }
        
  
    }






    return { 
        posts,
        error,
        obtenerComments,
        paginasInner,
        siguiente,
        pageInner,
        firstVis,
    
       }

}



export default getComments