import { ref } from "vue";
import { db } from '@/firebase/config.js'
import { getDoc, doc } from "firebase/firestore"




const getInfoUser = () => {


    let infoUserName = ref([])
    let infoUserName2 = ref([])
    const error = ref(null)
    
    const loadInfoUser = async (id) => {

          

        const docRef = doc(db, "users", id);

        try{
            const docSnap = await getDoc(docRef);

            if(docSnap.exists()) {

                

                var x = docSnap.data()
                infoUserName.value = x

              
                
    
                
               

            } else {
                console.log("Document does not exist")
            }

            
        
        }

        catch(err){
           
            error.value = err.message
            console.log(error.value)
         
      }
    }

    const loadInfoUser2 = async (id) => {

          

        const docRef = doc(db, "users", id);

        try{
            const docSnap = await getDoc(docRef);

            if(docSnap.exists()) {

                

                var x = docSnap.data()
                infoUserName2.value = x

              
                
    
                
               

            } else {
                console.log("Document does not exist")
            }

            
        
        }

        catch(err){
           
            error.value = err.message
            console.log(error.value)
         
      }
    }

    
   
  






    return { 
        loadInfoUser,
        infoUserName,
        loadInfoUser2,
        infoUserName2

     
    
       }

}



export default getInfoUser