<template>

  <div v-if="(visibleNav == true)" class="w-full navbar fixed z-2 bg-base-100 border-b border-slate-700 pr-8 z-10">
      <!-- <div class="flex-none lg:hidden">
        <label for="my-drawer-3" class="btn btn-square btn-ghost">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="inline-block w-6 h-6 stroke-current"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
        </label>
      </div>  -->


      <!-- <div class="flex w-40 pl-3 ">
        <img src="../assets/logo.png"/>
      </div> -->
      <!-- <div class="flex grow">
      </div> -->

      <div class="flex-none hidden lg:block">
        <ul class="menu menu-horizontal p-0">
          
          <router-link :to="{ name: 'main'}">
              <li class="">
                <a>
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" /></svg>
                  <p class="ml-5 tracking-[0.1em] text-sm">DASHBOARD</p> 
                </a>
              </li>
          </router-link>
          <router-link :to="{ name: 'calificacion'}">
            <li class="">
              <a>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                  </svg>
                  <p class="tracking-[0.1em] text-sm">QUALIFY</p> 
              </a>
            </li>
          </router-link >

          <router-link :to="{ name: 'forum'}">
            <li class="">
              <a>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                </svg>
                <p class="tracking-[0.1em] text-sm">COMMUNITY</p>
              </a>
            </li>
          </router-link >
    
        </ul>

       

        <div class="dropdown dropdown-end">
          <label tabindex="0" class="btn btn-ghost btn-circle">
            <div class="indicator">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
          </label>
          <div tabindex="0" class="mt-5 card card-compact dropdown-content w-52 shadow bg-slate-600 z-10">
            <div class="card-body z-50">
              <span class="font-bold text-lg">{{ user.displayName }}</span>
              <span class="text-info">{{ user.email }}</span>
            
              <div class="card-actions">
                <button @click="handleClick" class="btn  btn-sm btn-primary btn-block">SIGN OUT</button>
              </div>
            </div>
          </div>
        </div>


      </div>


  </div>


</template>

<script>

import useLogout from '@/composables/useLogout'
import getUser from '@/composables/getUser'
import { useRouter } from 'vue-router'
import { ref, watch } from '@vue/runtime-core'

export default {

  setup(){


    const visibleNav = ref();

    const {logout, error } = useLogout()
    const { user } = getUser()
    const router = useRouter()
  


    watch(user, () =>{

      if(user.value){
        
        visibleNav.value = true
        //console.log('user true', visibleNav.value)


      }else{
        visibleNav.value = false
        //console.log('user true', visibleNav.value)
      }


    })
    
 

    const handleClick = async () =>{



      await logout()
      if(!error.value){
        visibleNav.value = false
        router.push({name: 'welcome'})
      }

    }


   

    return {
      handleClick, 
      user,
      visibleNav
      
      
    }

  }

  

}
</script>

<style>

</style>