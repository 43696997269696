<template>
  
      
      
                 
                <div class="flex items-center pt-1.5 pb-1.5 w-[126px]" v-for="ind in subPairsInd" :key="ind">
                    <p class="mr-2 text-[12px] tracking-[0.10em]">{{ ind.nombreCorr }}</p>
                    <select class="select select-xs h-8 rounded-md bg-none pl-5 pr-5 text-[13px] font-light"  @change="handleChange(ind.id)"  :value="ind.powerHijo" :placeholder="ind.nombreCorr" :id="ind.id">
                        <option v-for="option in options" :key="option">
                            {{  option }}
                        </option>
                    </select>
                    
                    
                </div> 
       


              
             
    
        
 
 
  

  

     


</template>

<script>

import getSubPairsInd from '@/composables/getSubPairsInd'
import { ref } from '@vue/reactivity'
import { db } from '@/firebase/config.js';
import { doc, updateDoc, writeBatch } from "firebase/firestore"; 
import { onMounted, onUpdated } from '@vue/runtime-core';


export default {
emits:['sendPower'],
props: ['pair'],
component: {  },
setup(context){

    

     //Get Sub Pairs
    
    const { subPairsInd, errorSub, loadSubPairsInd, poderPapaResult} = getSubPairsInd('IND')
    loadSubPairsInd()

    const powerValue = ref("")

    
    


        const handleChange = (id) =>{
            let selectValue = document.getElementById(id);
            let poderHijoNumber = parseInt(selectValue.value) 
            let poderHijoNumberOpuesto = !poderHijoNumber

            updateDoc(doc(db, "pares_hijos", id), {  
            powerHijo: parseInt(selectValue.value )          
            })

            // if( poderHijoNumber === -1){
            //     updateDoc(doc(db, "pares_hijos", id_hijo), {  
            //     powerHijo: 1        
            //     })
            // }
            // if( poderHijoNumber === 1){
            //     updateDoc(doc(db, "pares_hijos", id_hijo), {  
            //     powerHijo: -1       
            //     })
            // }
            // if( poderHijoNumber === 0){
            //     updateDoc(doc(db, "pares_hijos", id_hijo), {  
            //     powerHijo: 0       
            //     })
            // }

        }

        //Obtener imagen flag de cada par
        const getImg = (img) =>{
           
            var imagesFlag = require.context('../../assets', false, /\.png$/)
            return imagesFlag('./' + img + ".png")
        }

        
        
        onUpdated(() => {
            //console.log(poderPapaResult['value']);
           // sendEvent1()

            //powerValue.value = poderPapaResult

        })


        // function sendEvent1(){
        //     //console.log(powerValue)
        //     context.emit('sendPower', powerValue)

        // }

     
        


        return { 
            
            subPairsInd,
            options: [0,-1, 1],
            handleChange,
            poderPapaResult,
            getImg,
            powerValue
            
            
            // updatePairs
            
  
        }


        

    }

}
</script>

<style>




</style>