<template>



  
<div class="flex flex-col lg:flex-row h-full">

      <div class=" h-full lg:w-[280px] px-12 py-8 lg:px-8 lg:py-4 lg:bg-[#252b37] lg:border-r border-slate-600 bg-">
        
        <CorrNewv2 />
        <div class="flex flex-row mt-5 gap-3">
    
          <v-btn class="p-2 w-auto "
            color="blue-grey"
            min-width="auto"
            @click="dialogQualify = !dialogQualify"
            ><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
            </svg>
          </v-btn>

          <v-btn class="btn xl:btn-sm text-[13px] xl:text-[11px]  rounded-[5px] tracking-[0.20em] shadow-none bg-slate-700 lg:bg-background" @click="modalReset = !modalReset">
          <p> Reset Esc </p> 
          </v-btn>
    
        </div>
                
                

                
      
            
            
      </div>  

      <div class=" grow grid mt-4 grid-cols-1  scroll1 lg:overflow-y-scroll lg:h-[100%] xl:grid-cols-5 xl:gap-0 xl:h-[100%] lg:mt-0">
        <div class=" xl:mt-0 col-span-4">
          <PairsEvaluationv2 />


          
        </div>
      </div>

</div>




<!-- Modals -->

<v-dialog
  v-model="dialogQualify"
  fullscreen
  :scrim="false"
  transition="dialog-bottom-transition"
>
  <v-card color="background" >
    <v-toolbar
      
      color="transparent"
    >
      <v-btn
        icon
        dark
        @click="dialogQualify = false"
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
</svg>

      </v-btn>
      <v-toolbar-title class="text-lg tracking-[0.15em] text-[15px] w-1/2 sm:w-full"> 
      <p class="text-[15px] font-semibold">CORRELATION</p>
      </v-toolbar-title>
      
      <v-spacer></v-spacer>
      <v-toolbar-items>
        
      </v-toolbar-items>
    </v-toolbar>
    
    <div class=" px-5">
    

    <div class=" grid grid-col-1 mt-8">

        
        <!-- <div class="grid grid-cols-1 sm:grid-cols-4 lg:grid-cols-8 justify-items-center grid-flow-row-dense gap-5 md:gap-10 p-0">

          <PairScore :pairs="pairs"  @sendPowFinal="recieveFinal" />

        </div> -->

        <div class="mb-5 flex justify-center items-center">

          <div class=" w-full xl:w-1/2 ">

          <div class="flex items-center justify-between">
          <p class="text-lg tracking-[0.30em] w-1/2 sm:w-full ">FOREX PAIRS</p>
          <v-btn class="btn xl:btn-sm text-[13px] xl:text-[11px]  rounded-[5px] tracking-[0.20em] shadow-none bg-primary" @click="dialogQualify = false, handleUpdate()">
     <p> Update Pairs </p> 
    </v-btn>
          
          </div>


          <v-divider class="my-5"></v-divider>

          <div class="w-full">

            <div class=" columns-2 gap-5 md:columns-4 md:gap-8">
              <PairScore :pairs="pairs"   />
            </div>

          </div>

      
        </div>

        </div>

        



    </div>

    </div>

  </v-card>
</v-dialog>

<v-dialog class="lg:w-1/3"
v-model="modalReset"
>
  <v-card>
    <v-card-text class="py-5">
      <p class="">Are you sure to reset the scenarios?</p>
      <div class="flex flex-row mt-5 gap-4">
        <v-btn class="text-background" color="warning"  @click="modalReset = false">NO</v-btn>
        <v-btn class="text-background" color="primary"  @click="resetVariables(), modalReset = false">YES</v-btn>
      </div>
    </v-card-text>
    
    
  </v-card>
</v-dialog>





</template>
  
  <script>

//component 
import Qualify from '@/views/calificacion.vue'
import PairScore from '@/components/score/PairScore.vue'
import SinglePares8 from '@/components/score/SinglePares8.vue'
import CorrNewv2 from '@/components/groupPairs/CorrNewv2.vue'
import PairsEvaluationv2 from '@/components/groupPairs/PairsEvaluationv2.vue'
import getUser from '@/composables/getUser'

//composables
import getPairs from '@/composables/getPairs'
import updatePower from '@/composables/updatePowers'

//libraries
import { db } from '@/firebase/config.js';
import { collection, doc, updateDoc, writeBatch, onSnapshot, query, where } from "firebase/firestore";
import { ref } from '@vue/reactivity';
import { onMounted, onUpdated } from '@vue/runtime-core';
import { useRouter } from 'vue-router'




export default {

components: { PairScore, updatePower, SinglePares8, CorrNewv2, PairsEvaluationv2, Qualify },

setup(){

  const { user } = getUser()  
  const drawer2 = ref(true)
  const modalReset = ref(false)
  const modalResetMob = ref(false)
  const dialogQualify = ref(false)
  const showFlag = ref(false)
  const uId = user._rawValue.uid
  

 

  


  //Get Main Pairs
  const { pairs, error, load} = getPairs()
  load()

  const { upPower, errorUp } = updatePower()

  const router = useRouter()


  //RECIBIR POWER DE CADA PAR 
  const recieveFinal = () =>{

    
  }


    onUpdated(() => {
            
          
    })

    const handleUpdate = ()=>{

      upPower("v1")
      //router.push({name: 'main'})

    }

    const resetVariables = () =>{
    
            const q = query(collection(db, "pares_hijos"), where("owner", "==", uId))
            const paresCompletos = onSnapshot(q, (querySnapshot) => {
                querySnapshot.forEach((doc2) => {                
                    updateDoc(doc(db, "pares_hijos", doc2.id), {  
                                esc: "",
                                pri_vela: "",
                                brusquito: "",
                                ob_os: "",
                                ad_es: "",
                                esc_inicio: "",
                                esc_emas: "",
                                esc_canal: "",
                                esc_ob_os: "",
                                fav: "no",
                                             
                    })
                });
                paresCompletos()
            })
            

        }


    
    //create a document // Edit Document

    // const createPair = async () => { await setDoc(doc(db, "pares_hijos", "U-USDJPY"), {
      
    //   hijo: 'JPY',
    //   id: 'U-USDJPY',
    //   id_inverso:'J-USDJPY',
    //   nombreCorr: "USDJPY",
    //   papa: 'USD',
    //   powerHijo: 0,
    //   powerPapa: 0
      
    // })


    // }

    const createPair = async () => { await setDoc(doc(db, "pares_hijos", "I-AUS200"), {
      
 
      id: 'I-AUS200',
      nombreCorr: "AUS200",
      powerHijo: 0, 
      par: 'IND'
      
    })


    }


  return{ pairs,
          createPair,
          recieveFinal, 
          resetVariables,
          user, 
          drawer2,
          modalReset,
          modalResetMob,
          dialogQualify,
          showFlag,
         
    
    
          errorUp,
          updatePower,
          handleUpdate }



}


}
</script>
  
<style scoped>

.pair_score{
  column-fill: auto !important;
}

.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform .2s ease-in-out;
}


</style>