<template>
 
 <v-app :theme="theme" class="bg-background lg:h-screen">
  
  <v-navigation-drawer v-model="drawer" temporary class="pt-[65px]">
    <template v-slot:prepend>
          
          <div class="  flex items-center py-4 px-6 border-b border-slate-700">
            <div class="flex flex-row items-center gap-4">
              <v-avatar v-if="infoUserName.author_photoURL == ''" class="h-[40px] w-[40px] bg-primary">
                <p class="font-semibold text-lg"> {{ infoUserName.displayName.charAt(0) }} </p>
              </v-avatar>
              <v-avatar v-else class="h-[40px] w-[40px] ">
                <v-img  :src="infoUserName.author_photoURL"></v-img>
              </v-avatar>

              <p class="tracking-wider font-normal">{{ infoUserName.displayName }}</p>
            </div>
           </div>


        </template>

        <v-divider></v-divider>

        <ul class=" menu flex flex-col mt-2">
            
            <router-link :to="{ name: 'main'}">
              <v-btn variant="text" class="rounded-[7px] h-[44px] text-btnMenu">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" /></svg>
                    <p class=" ml-2 tracking-[0.15em] text-sm">DASHBOARD</p> 
              </v-btn>
            </router-link>


            <router-link :to="{ name: 'forum'}">
              <v-btn variant="text" class="rounded-[7px] h-[44px] text-btnMenu">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                  </svg>
                  <p class="ml-2 tracking-[0.1em] text-sm ">COMMUNITY</p>
              </v-btn>
            </router-link >
      
          </ul>

        <template v-slot:append>
          <div class="pa-2">
            <v-btn @click="handleSignout()" class="bg-slate-500"  block>
              Logout
            </v-btn>
          </div>
        </template>
  </v-navigation-drawer>

  <v-app-bar color="background" v-if="(visibleNav == true)" class=" flex align-middle min-h-[4rem]  lg:pl-1 lg:pr-8  shadow-lg border-b z-[2000] border-slate-700">

        <v-app-bar-nav-icon class="lg:hidden" @click="drawer = !drawer"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
          <path fill-rule="evenodd" d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z" clip-rule="evenodd" /></svg>
        </v-app-bar-nav-icon>

        <div class="flex w-40 pl-3 ">
          <router-link :to="{ name: 'main'}" class="">
            <img src="@/assets/logo.png"/>
          </router-link>
        </div>
        <div class="flex grow">
        </div>

        <div class="flex-none hidden lg:block">
          <ul class=" menu inline-flex flex-row h-full ">
            
            <router-link :to="{ name: 'main'}" class="">
              <v-btn variant="text" class="rounded-[7px] h-[44px] text-btnMenu">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" /></svg>
                    <p class=" ml-2 tracking-[0.15em] text-sm">DASHBOARD</p> 
              </v-btn>
            </router-link>

            <!-- <router-link :to="{ name: 'calificacion'}">

              <v-btn variant="text" class="rounded-[7px] h-[44px] text-btnMenu">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" /></svg>
                    <p class=" ml-2 tracking-[0.1em] text-sm">QUALIFY</p> 
              </v-btn>
              
            </router-link > -->

            <router-link :to="{ name: 'forum'}">
              <v-btn variant="text" class="rounded-[7px] h-[44px] text-btnMenu">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                  </svg>



                  <p class="ml-2 tracking-[0.1em] text-sm ">COMMUNITY</p>
              </v-btn>
            </router-link >
      
          </ul>


       

        <!-- <div class="dropdown dropdown-end">
          <label tabindex="0" class="btn btn-ghost btn-circle">
            <div class="indicator">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </div>
          </label>
          <div tabindex="0" class="mt-5 card card-compact dropdown-content w-52 shadow bg-slate-600 z-10">
            <div class="card-body z-50">
              <span class="font-bold text-lg">{{ user.displayName }}</span>
              <span class="text-info">{{ user.email }}</span>
            
              <div class="card-actions">
                <button @click="handleClick" class="btn  btn-sm btn-primary btn-block">SIGN OUT</button>
              </div>
            </div>
          </div>
        </div> -->



        <!-- Theme Change icon -->
        <v-app-bar-nav-icon class=" mr-2 text-btnMenu hidden" @click="onClick">
          <svg v-if="theme == 'lightTheme'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z" />
          </svg>
          
         
          <svg v-if="theme == 'darkTheme'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z" />
          </svg>
        </v-app-bar-nav-icon>


        
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      location="end"
      
    >
      <template v-slot:activator="{ props }">

        <v-app-bar-nav-icon class="ml-5" v-bind="props">
          <v-badge dot content="2" color="#64C3A5">
            <v-avatar v-if="infoUserName.author_photoURL == ''" class="h-[40px] w-[40px] bg-primary">
               <p class="font-semibold text-lg"> {{ infoUserName.displayName.charAt(0) }} </p>
            </v-avatar>
            <v-avatar v-else class="h-[40px] w-[40px] ">
              <v-img  :src="infoUserName.author_photoURL"></v-img>
            </v-avatar>
          </v-badge>
         </v-app-bar-nav-icon>


        
      </template>

      <v-card min-width="200">
        <v-list class="mx-auto">
          <v-list-item link>
            <div class="flex flex-row gap-6">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
              </svg>

              <p class="grow">Mentions</p>
                  <v-badge
                    color="error"
                    content="6"
                    inline
                  ></v-badge>
            </div>
          </v-list-item>

          <v-list-item link>
            <div class="flex flex-row gap-6">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
              </svg>


              <p class="grow">Messages</p>
                  <v-badge
                    color="error"
                    content="6"
                    inline
                  ></v-badge>
            </div>
          </v-list-item>

   
   
        </v-list>

       

        

        <v-card-actions>
          <v-spacer></v-spacer>

          
          <v-btn
          class="rounded-[5px]"
            color="primary"
            variant="text"
            @click="handleSignout(), menu = false"
          >
          <p class="tracking-[0.20em]"> SIGN OUT </p>
            
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>


    











        
       
        
        
        

        </div>

  </v-app-bar>

  <v-main class="h-full">
      
    
      <router-view />

    
  </v-main>






</v-app>











 



</template>


<script>
import NavBar from '@/components/NavBar.vue'
import NavBarMobile from '@/components/NavBar_mobile.vue'
import { ref } from '@vue/reactivity'

import useLogout from '@/composables/useLogout'
import getUser from '@/composables/getUser'
import getInfoUser  from "@/composables/forum/getInfoUser"
import { useRouter } from 'vue-router'
import { watch } from '@vue/runtime-core'
import { ChartBarIcon } from '@heroicons/vue/outline'


export default {
  components: { NavBar, NavBarMobile , ChartBarIcon  },
  data: () => ({
      fav: true,
      menu: false,
      message: false,
      hints: true,
    }),
  setup(){
    const { user } = getUser()
    const drawer = ref(false)
    const sideNav = ref()
    const visibleNav = ref()
    const {logout, error } = useLogout()
    
    const router = useRouter()
    const { loadInfoUser, infoUserName } = getInfoUser()

    

    

    

    const theme = ref('darkTheme')

  function onClick () {
    theme.value = theme.value === 'lightTheme' ? 'darkTheme' : 'lightTheme'
  }

  
  

  watch(user, () =>{

      if(user.value){
        visibleNav.value = true
        var userId = user.value.uid
        loadInfoUser(userId)
        //console.log('user true', visibleNav.value)
      }else{
        visibleNav.value = false
        //console.log('user true', visibleNav.value)
      }
  })

  const handleSignout = async () =>{

      await logout()
      if(!error.value){
        visibleNav.value = false
        router.push({name: 'welcome'})
      }

    }


    return{
      sideNav,
      onClick,
      theme,
      user,
      visibleNav,
      drawer,
      handleSignout,
      infoUserName,
   

    }
  }

}

</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.v-application {
   /* font-family: 'Lato', sans-serif !important; */
   font-family: 'Open Sans', sans-serif !important;
    
 }

a.router-link-active svg,
a.router-link-active p {
    color: #65c3a5;
    font-weight: 600;
}


.ce-block__content,
.ce-toolbar__content{
  max-width: 100% !important;
  
}



.ce-popover{
  left:0;

}

/* .commentEditor .ce-toolbar__actions{
  left: -70px;
  width: fit-content;
} */




@media (max-width: 650px){
.ce-toolbar__plus,
.ce-toolbar__settings-btn
 {
 
  color: #181c25 !important;
  width: 26px !important;
  height: 26px !important;
  border: 0px solid #e8e8eb !important;
}

.ce-popover__overlay{
  opacity: 0.8 !important;
}

#main_editor.ce-popover{
  bottom:60px;
}



}

@media (min-width: 651px){
.codex-editor--narrow .ce-toolbar__actions {
    right: 100%;
}

.ce-toolbar__plus, 
.ce-toolbar__settings-btn{
  color:#eff2f5 !important;
  
}

}





.ce-toolbar__plus:hover, .ce-toolbar__settings-btn:hover{
  background-color: #65c3a5 !important;
  
}



.simple-image {
    
    margin-top: 8px;
    margin-bottom: 8px;
}

.simple-image input {
    width: 100%;
    padding: 10px;
    border: 1px solid #e4e4e4;
    border-radius: 3px;
    outline: none;
    font-size: 14px;
}

.simple-image img {
    max-width: 100%;
    
}

.menu li{

    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;

}

h2{
@apply text-2xl tracking-wider font-semibold !important;
}



h3{
@apply text-xl tracking-wider font-semibold !important;
}

.v-list-item--link::before { background-color: red; }




.scroll2::-webkit-scrollbar-track,
.scroll1::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 5px;
	background-color: #181c25;
}

.scroll2::-webkit-scrollbar,
.scroll1::-webkit-scrollbar
{
	width: 5px;
	background-color: #181c25;
}

.scroll2::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #64C3A5;
}
.scroll1::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}






#style-1::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 5px;
	background-color: #252b37;
}

#style-1::-webkit-scrollbar
{
	width: 5px;
	background-color: #252b37;
}

#style-1::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}

#style-2::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 5px;
	background-color: #181c25;
}

#style-2::-webkit-scrollbar
{
	width: 5px;
	background-color: #181c25;
}

#style-2::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #64C3A5;
}



/* image zoom editor.js  */
.zoomIcon{

position:absolute; 
top: 8px;
right:8px;
border-radius: 5px;
cursor: pointer;
opacity: 0%;
color: #64C3A5;

}

.zoomIcon:hover{


opacity: 100%;



}






</style>
