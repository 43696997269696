
import { useRouter } from 'vue-router'

const handleNavMain = () => {

    const router = useRouter()

    const handleNav = (link, filter) => {

        console.log(filter)

        router.push({ name: link, params : {filter: filter}
         })

    }
   



    return { 
        handleNav
       }


}




export default handleNavMain