<template>
  <form @submit.prevent="handleSubmit">
    <input class="w-full my-5 p-[10px] border-b-2 border-solid border-neutral-400" type="email" required placeholder="Email" v-model="email">
    <input class="w-full my-5 p-[10px] border-b-2 border-solid border-neutral-400  text-neutral-400" type="password" required placeholder="Password" v-model="password">
    <p> {{ error }}</p>
    <v-btn @click="handleSubmit" class="text-[13px] w-full xl:text-[15px] h-[55px] rounded-[5px] tracking-[0.20em] shadow-none bg-primary mt-3">
  Iniciar Sesión
    </v-btn>
  </form>
</template>







<script>
import { ref } from 'vue'
import useLogin from '@/composables/useLogin.js'

export default {
  setup(props, context) {
    // refs
    const email = ref('')
    const password = ref('')

    const {error, login } = useLogin()


    const handleSubmit = async () => {
      await login(email.value, password.value)


      if(!error.value){
        console.log('user logged in')
        context.emit('login')
      }
    }
    return { email, password, handleSubmit, error }
  }
}
</script>

<style>


</style>