<template>


      <div class="flex flex-row px-4 py-5 lg:pl-7 lg:py-5">


        <v-avatar v-if="infoUserName.author_photoURL == ''" class="h-[40px] w-[40px] bg-primary">
          
               <p class="font-semibold text-lg"> {{ infoUserName.displayName.charAt(0) }} </p>
               
              
        </v-avatar>

        <v-avatar v-else class="h-[40px] w-[40px] ">
          
              <v-img  :src="infoUserName.author_photoURL"></v-img>
               
              
        </v-avatar>
       





          <div class=" border-slate-200 flex flex-col w-full pl-3 pr-2 rounded-lg ">
            
            <div class="flex gap-2">
              <div class="flex flex-col grow ">
                <p class=" tracking-wider font-bold text-sm text-primary">{{ infoUserName.displayName }}</p>  <span class=" mt-[2px] text-xs font-medium italic text-neutral-400 grow">{{ timeAgo(postComment.time)}} </span>  
              </div>
              <div class="flex gap-2">
                

              <v-btn size="x-small" v-if="postComment.author_id == user.uid && editMode == true"
                  class="
                    text-[13px]
                    xl:text-[10px]
                    rounded-[5px]
                    tracking-[0.15em]
                    shadow-none
                    bg-primary
                 
                  "
                  @click="saveEdit(props.post.id)"
                >
                <p>Save</p>
              </v-btn>

              <v-btn size="x-small" v-if="postComment.author_id == user.uid && editMode == true"
                  class="
                    text-[13px]
                    xl:text-[10px]
                    rounded-[5px]
                    tracking-[0.15em]
                    shadow-none
                    bg-warning
                
                  "
                  @click="handleEdit()"
                >
                <p>Cancel</p>
              </v-btn>

               
              </div>
              <v-btn v-if="postComment.author_id == user.uid && editMode == false" class=" z-10 w-[20px] h-[20px] text-[13px] xl:text-[11px] tracking-[0.20em] shadow-none "
                          icon="mdi:mdi-pencil-outline"
                          variant="plain"
                          size="x-small"
                          @click="handleEdit()"
              ></v-btn>
            </div>

            
              <div class=" mt-2 h-fit text-neutral-300 text-sm" :id="post.id"></div>
            

        
          </div>

    
      </div>

      






            

            




</template>

<script>

import getEditorCom from "@/composables/forum/getEditorCom"
import getInfoUser  from "@/composables/forum/getInfoUser"

//imports vue
import { ref } from "vue"
import { onMounted, onUnmounted, onUpdated } from "@vue/runtime-core"
import getTimeAgo from "@/composables/forum/timeAgo"
import getUser from "@/composables/getUser"

import { db } from '@/firebase/config.js'
import { getDoc, doc } from "firebase/firestore"






export default {

     props: [ 'post' ],
     setup(props){

         //get domposables functions
        
        const { timeAgo } = getTimeAgo()
        let authoruid = props.post.author_id
        const { loadInfoUser, infoUserName } = getInfoUser()

       


        const {
        loadEditorMainCom,
        loadEditorComment,
        postComment,
        handleEdit,
        editMode,
        saveEdit,
        snackbar,
        textSnack,
        handleSnack,
        createComment,
        destroyEditor
        } = getEditorCom()

        
        const { user } = getUser()
        loadInfoUser(authoruid)

    
       

        onMounted(() => {
 
        loadEditorMainCom(true,props.post.id, props.post.id)

        });

        onUnmounted(() => {

              destroyEditor()

        });

        



        return{
            props,
            user,
            timeAgo,
            postComment,
            handleEdit,
            editMode,
            saveEdit,
            snackbar,
            textSnack,
            handleSnack,
            createComment,
            infoUserName



        }

     }



}
</script>

<style>

</style>