<template>




<v-navigation-drawer class="lg:hidden"
        v-model="catDown"
        location="bottom"
        temporary
      >
        <v-list class="p-0" density="compact">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :value="item"
          active-color="primary"
          :active="item.activese"
          @click="handleNav('forumFilter', item.filter)"
          
        >
        <div class="flex flex-row items-center gap-3 cursor-pointer  hover:text-[#64C3A5] ease-in-out duration-300 ">
          <v-icon :icon="item.icon" size="x-small" ></v-icon>
          <v-list-item-title v-text="item.text" class="text-sm"></v-list-item-title>
        </div>
    
        </v-list-item>
      </v-list>
</v-navigation-drawer>

  <div class="flex flex-col lg:flex-row lg:h-full  ">

    
    
    <!-- sub nav categories-->
    <div @click="catDown = !catDown" class="gap-2 w-full px-6 py-3 bg-slate-600 flex fixed z-[20] lg:hidden">

        <p class="grow uppercase tracking-[0.18em]">Categorias</p>
        <v-icon v-if="catDown == false" icon="mdi:mdi-chevron-down"></v-icon>
        <v-icon v-if="catDown == true" icon="mdi:mdi-close"></v-icon>

    </div>  

    <div v-if="postGlobal.author_id == user.uid && editMode == true" class="bottom-0 border-t border-slate-600  gap-2 w-full px-6 py-3 bg-background flex items-center justify-center fixed z-[20]" >
          <v-btn size="small" v-if="postGlobal.author_id == user.uid && editMode == true"
                  class="
                    text-[13px]
                    xl:text-[11px]
                    rounded-[5px]
                    tracking-[0.20em]
                    shadow-none
                    bg-primary
                  "
                  @click="saveEdit(props.id)"
                >
                <p>Save</p>
          </v-btn>

          <v-btn size="small" v-if="postGlobal.author_id == user.uid && editMode == true"
                  class="
                    text-[13px]
                    xl:text-[11px]
                    rounded-[5px]
                    tracking-[0.20em]
                    shadow-none
                    bg-warning
                  "
                  @click="handleEdit()"
                >
                <p>Cancel</p>
          </v-btn>

    </div>
    
    <!-- sidebar -->
    <div class="hidden h-full lg:block lg:w-[280px] px-12 py-8 lg:px-0 lg:py-0 lg:bg-[#252b37] lg:border-r border-slate-600">
      <!-- Photo + User  -->
      <div class=" hidden flex items-center py-4 px-6 border-b border-slate-700">
        <div class="flex flex-row items-center gap-4">
          <v-avatar v-if="infoUserName.author_photoURL == ''" class="h-[40px] w-[40px] bg-primary">
            <p class="font-semibold text-lg"> {{ infoUserName.displayName.charAt(0) }} </p>
          </v-avatar>
          <v-avatar v-else class="h-[40px] w-[40px] ">
            <v-img  :src="infoUserName.author_photoURL"></v-img>
          </v-avatar>

          <p class="tracking-wider font-normal">{{ user.displayName }}</p>
        </div>
      </div>

    <div class="flex flex-col h-auto text-neutral-300">
      <!-- categories title -->
      <div
        class="
          px-4
          py-1.5
          flex flex-row
          items-center
          border-b border-slate-700
          bg-background
        "
      >
        <p
          class="
            uppercase
            tracking-[0.18em]
            text-xs
            font-semibold
            text-neutral-300
          "
        >
          Categories
        </p>
      </div>

      <!-- side menu -->
      <v-list class="p-0" density="compact">
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :value="item"
          active-color="primary"
          :active="item.activese"
          @click="handleNav('forumFilter', item.filter)"
        >
          <div
            class="
              flex flex-row
              items-center
              gap-3
              cursor-pointer
              hover:text-[#64C3A5]
              ease-in-out
              duration-300
            "
          >
            <v-icon :icon="item.icon" size="x-small"></v-icon>
            <v-list-item-title
              v-text="item.text"
              class="text-sm"
            ></v-list-item-title>
          </div>
        </v-list-item>
      </v-list>
    </div>
    </div>

    <!-- column post -->
    <div class=" mt-[50px] py-5 lg:py-8 lg:mt-0 scroll1 lg:max-h-screen basis-7/12 lg:bg-[#252b37] lg:overflow-y-scroll block drop-shadow-lg">
      
      <div class="px-5 sm:px-16">
      <!-- {{post header}} -->
        <div v-if="postGlobal.length != 0 ">

          <div  class="flex  items-center gap-5">
            <div class="grow flex gap-4 w-full">
              <v-avatar v-if="infoUserName2.author_photoURL == ''" class="h-[40px] w-[40px] bg-primary">
                <p class="font-semibold text-lg"> {{ infoUserName2.displayName.charAt(0) }} </p>
              </v-avatar>
              <v-avatar v-else class="h-[40px] w-[40px] ">
                <v-img  :src="infoUserName2.author_photoURL"></v-img>
              </v-avatar>
                  
              <div  class="flex flex-col gap-2 grow">
                
                <p class="text-neutral-300 font-bold text-lg tracking-wider"> {{postGlobal.title}} </p>
        
                <div class="flex gap-2">
                  <!-- <span class="text-xs font-medium text-neutral-300">{{ postGlobal.UserName }}</span> -->
                    <span class=" capitalize text-xs font-medium italic text-neutral-300"> [ {{ postGlobal.category }} ]  </span>
                  <span class="text-xs font-medium italic text-neutral-400">{{ timeAgo(postGlobal.time)}} </span>
                </div>
                
              </div>

            </div>

            <div class="flex gap-2">
              <!-- <v-btn size="small" v-if="postGlobal.author_id == user.uid && editMode == false"
                  class="
                    text-[13px]
                    xl:text-[11px]
                    rounded-[5px]
                    tracking-[0.20em]
                    shadow-none
                    bg-background
                  "
                  @click="handleEdit()"
                >
              
            <p>Edit</p>
              </v-btn> -->

              <v-btn  v-if="postGlobal.author_id == user.uid && editMode == false" class=" z-10 w-[20px] h-[20px] text-[13px] xl:text-[11px] tracking-[0.20em] shadow-none "
                          icon="mdi:mdi-pencil-outline"
                          variant="plain"
                          size="x-small"
                          @click="handleEdit()"
              ></v-btn>

              
            </div>

              
          </div>

          <!-- <v-btn size="small" v-if="postGlobal.author_id == user.uid && editMode == true"
                  class="
                    text-[13px]
                    xl:text-[11px]
                    rounded-[5px]
                    tracking-[0.20em]
                    shadow-none
                    bg-primary
                  "
                  @click="saveEdit(props.id)"
                >
                <p>Save</p>
          </v-btn>

          <v-btn size="small" v-if="postGlobal.author_id == user.uid && editMode == true"
                  class="
                    text-[13px]
                    xl:text-[11px]
                    rounded-[5px]
                    tracking-[0.20em]
                    shadow-none
                    bg-warning
                  "
                  @click="handleEdit()"
                >
                <p>Cancel</p>
          </v-btn> -->
          
          <!-- <v-divider class="border-slate-600 my-6"></v-divider> -->

        </div>

        <!-- {{post body}} -->
     
        <div class=" post-reader h-fit max-h-fit text-neutral-300 mt-8 text-sm md:pl-0" id="main_editor"></div>
     
        

      </div>
    </div>

    <!-- column comments -->
    <div class="bg-background  basis-5/12 lg:overflow-y-scroll lg:max-h-screen " id="style-2">

        <div class=" text-neutral-300 flex items-center px-5 py-2 gap-2 lg:fixed drop-shadow-2xl bg-slate-700 z-[100] w-full">

          <v-icon icon="mdi:mdi-comment-outline" size="small"></v-icon>
            <p class=" font-normal uppercase text-base tracking-[0.18em]">Comments</p>
        </div>
       
      <div class="mt-5 lg:mt-10">
        

          <div v-if="posts.length">

              
              <div class="flex flex-col">
                <div class="" v-for="post in posts" :key="post.id" >
    
                    <SingleComment :post="post" />
                    <v-divider class="border-slate-600"></v-divider>
                </div>
              </div>

              <!-- pagination -->
          <div class="text-center">
            <v-pagination class="mt-5 text-xs pagination-flv text-neutral-400"
              v-model="page"
              :length="paginas"
              rounded="circle"
              @click="siguiente(page, props.id)"
              total-visible="5"
            ></v-pagination>
          </div>

          </div>

          <div v-else-if="posts.length == 0" class="w-full text-center p-10">
          
          </div>

          <!-- spinner waiting for posts -->
        <div v-else class="w-full text-center p-10">
          <v-divider class="border-slate-600"></v-divider>
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>

      
      </div>

       <!-- {{Add comment}} -->
      <div class="mt-5 px-6"  >

        <div class="flex gap-2 items-center text-neutral-500">
            <!-- <v-icon icon="mdi:mdi-comment-outline" size="small"></v-icon> -->
        <p class=" font-semibold uppercase text-base tracking-wider">Add Comment</p>

        </div>
        
        <div class="flex items-start gap-5 mt-2">

            <div class="relative px-16 py-2 grow border-slate-700 border-2 rounded-md bg-background">
              <div class="  text-neutral-300 text-sm commentEditor" id="comment_editor"></div>
              <v-btn 
                
                  prepend-icon="mdi:mdi-comment-outline"
                  class=" z-10 text-[13px] xl:text-[11px] rounded-[5px] tracking-[0.20em] shadow-none bg-primary absolute right-3 bottom-3"
                  @click="createComment(props.id)"
                >Publish</v-btn>
              
            </div>

            <!-- <v-avatar class="h-[35px] w-[35px]">
              <v-img :src="user.photoURL"></v-img>
            </v-avatar> -->

        </div>
        
        
        
      </div>  

    </div>

       
    
  </div>



  <!-- imgs Modal -->
  <div
    id="modal"
    class="
      hidden
      fixed
      top-0
      left-0
      z-[2000]
      w-screen
      h-screen
      bg-black/70
      flex
      justify-center
      items-center
    "
  >
    <!-- The close button -->
    <a
      class="fixed z-90 top-6 right-8 text-white text-5xl font-bold"
      href="javascript:void(0)"
      onclick="closeModal()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </a>
    <!-- A big image will be displayed here -->
    <img id="modal-img" class="max-w-auto max-h-[90%] object-cover" />
  </div>

  <!-- snack bar -->
  <v-snackbar
      v-model="snackbar"
      color="primary"
      :offset="50"
    
    >
      {{ textSnack }}

      <template v-slot:actions>
        <v-btn
          color=""
          variant="plain"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
  </v-snackbar>
</template>

<script>
//composables
import getPost from "@/composables/forum/getPost.js"
import getTimeAgo from "@/composables/forum/timeAgo"
import getUser from "@/composables/getUser"
import getInfoUser  from "@/composables/forum/getInfoUser"
import handleNavMain from "@/composables/forum/handleNavMain"

import getEditor from "@/composables/getEditor"


//imports vue
import { ref, computed, reactive } from "vue"
import { onMounted, onUnmounted, onUpdated } from "@vue/runtime-core"
import { useRouter } from "vue-router"
import { watch } from '@vue/runtime-core'

//imports Firebase
import { getAuth, updateProfile } from "firebase/auth"



//comentarios
import getComments from '@/composables/forum/getComments'
import SingleComment from '@/components/forum/singleComment.vue'



export default {
  props: ["id"],
  components: { SingleComment },
  setup(props) {
    //get domposables functions
    const { user } = getUser()
    const auth = getAuth()
    const { timeAgo } = getTimeAgo()
    const { handleNav } = handleNavMain()
    const {
        loadEditorMain,
        loadEditorComment,
        postGlobal,
        handleEdit,
        editMode,
        saveEdit,
        snackbar,
        textSnack,
        handleSnack,
        createComment,
        destroyEditor
        } = getEditor()
    const { 
        posts,
        error,
        obtenerComments,
        paginasInner,
        siguiente,
        pageInner,
        totalInner,
        firstVis
      } = getComments()
      const { loadInfoUser, infoUserName, loadInfoUser2, infoUserName2 } = getInfoUser()
      const catDown = ref(false)


      //console.log(posts)

  

    loadInfoUser(user.value.uid)

    watch(postGlobal, () =>{

      if(user.value){
        loadInfoUser2(postGlobal.value.author_id)
      }else{
        visibleNav.value = false
        //console.log('user true', visibleNav.value)
      }

    
  })

    
 
  






    //declaring variables
    

    const drawer2 = ref(true);
    let items = ref([
      {
        text: "All Posts",
        icon: "mdi:mdi-comment-multiple-outline",
        activese: false,
        filter: "all",
      },
      {
        text: "My Posts",
        icon: "mdi:mdi-folder-outline",
        activese: false,
        filter: "mine",
      },
      {
        text: "Trades",
        icon: "mdi:mdi-chart-box-outline",
        activese: false,
        filter: "trades",
      },
      // {
      //   text: "Markups",
      //   icon: "mdi:mdi-bookmark-outline",
      //   activese: false,
      //   filter: "markups",
      // },
      {
        text: "Mindset",
        icon: "mdi:mdi-fire",
        activese: false,
        filter: "mindset",
      },
      {
        text: "Tutorials",
        icon: "mdi:mdi-cellphone-link",
        activese: false,
        filter: "tutorials",
      },
    ]);
    

    let paginas = paginasInner
    let page = pageInner
    let total = totalInner


    //loading editors.js
    loadEditorMain(true, props.id, "main_editor")
    loadEditorComment(false, props.id, "comment_editor")

    


    

    onMounted(() => {

        obtenerComments(props.id)
        


    });

    onUnmounted(() => {

        destroyEditor()

    });

    //LOAD EDITOR

    return {
      props,
      drawer2,
      user,
      timeAgo,
      items,
      handleNav,
      postGlobal,
      handleEdit,
      editMode,
      saveEdit,
      snackbar,
      textSnack,
      handleSnack,
      createComment,
      paginas,
      page,
      total,
      siguiente,
      posts,
      SingleComment,
      infoUserName,
      infoUserName2,
      catDown
     
     
    
    };
  },
};
</script>

<style scope > 
.post-reader .ce-block {
  min-width: 100% !important;
  max-width: 100% !important;
}

.ce-popover__item, .ce-inline-toolbar{
@apply text-neutral-400 !important;

}




</style>