import { ref } from "vue";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '@/firebase/config.js';


const user = ref(auth.currentUser)

  const getUser = () =>{

    onAuthStateChanged(auth, (_user) => {
        if (_user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const uid = _user.uid;
          //console.log('User state change. Current user is: ', _user);
          user.value = _user
    
          // ...
        } else {
          // User is signed out
          // ...
          
        }
      })


    return { user}

  }


  export default getUser

  

