<template>
<div class="card-body py-0 rounded-xl h-fit">
   
    <div v-for="pair in pairsFiltered" :key="pair" class="flex items-center gap-2 p-[0px] w-full  md:gap-2 ">
    

            <div class="w-auto  flex items-center rounded-tl-md rounded-tr-md   h-7">
                <span  class="  inline-block align-middle w-4 mr-4 opacity-30" v-if="(pair.power < 4) && (pair.power> -3)"><img  :src="getImg(pair.nombre)" alt=""></span> 
                <span  class="  inline-block align-middle w-4 mr-4" v-else><img  :src="getImg(pair.nombre)" alt=""></span> 

                <p class=" w-[40px] text-sm card-title tracking-[0.14em] opacity-30" v-if="(pair.power < 4) && (pair.power> -3)">{{ pair.nombre}}</p>
                <p class=" w-[40px] text-sm card-title tracking-[0.14em]" v-else>{{ pair.nombre}}</p>
                <p class=" w-[25px] text-sm tracking-normal text-center opacity-30" v-if="(pair.power < 4) && (pair.power> -3)"> {{ pair.power }}</p>
                <p class=" w-[25px] text-sm tracking-normal text-center" v-else> {{ pair.power }}</p> 
            </div>

            <div class=" h-bar rounded-xl w-[6.6%] ml-1 bg-orange-600"  v-if="pair.power === -7 "></div>
            <div class=" h-bar rounded-xl w-[13.2%] ml-1 bg-orange-600"  v-if="pair.power === -6 "></div>
            <div class=" h-bar rounded-xl w-[19.8%] ml-1 bg-orange-500"  v-if="pair.power === -5 "></div>
            <div class=" h-bar rounded-xl w-[26.4%] ml-1 bg-orange-400"  v-if="pair.power === -4 "></div>
            <div class=" h-bar rounded-xl w-[33%] ml-1 bg-orange-400 opacity-20"  v-if="pair.power === -3 "></div>
            <div class=" h-bar rounded-xl w-[39.6%] ml-1 bg-orange-300 opacity-20"  v-if="pair.power === -2 "></div>
            <div class=" h-bar rounded-xl w-[46.2%] ml-1 bg-yellow-300 opacity-20"  v-if="pair.power === -1 "></div>
            <div class=" h-bar rounded-xl w-[50%] ml-1 bg-yellow-300 opacity-20"  v-if="pair.power === 0 "></div>
            <div class=" h-bar rounded-xl w-[59.4%] ml-1 bg-green-300 opacity-20"  v-if="pair.power === 1 "></div>
            <div class=" h-bar rounded-xl w-[66%] ml-2 bg-green-300 opacity-20"  v-if="pair.power === 2 "></div>
            <div class=" h-bar rounded-xl w-[72.6%] ml-1 bg-green-400 opacity-20"  v-if="pair.power === 3 "></div>
            <div class=" h-bar rounded-xl w-[79.2%] ml-1 bg-green-400"  v-if="pair.power === 4 "></div>
            <div class=" h-bar rounded-xl w-[85.8%] ml-1 bg-green-500"  v-if="pair.power === 5 "></div>
            <div class=" h-bar rounded-xl w-[92.4%] ml-1 bg-green-600"  v-if="pair.power === 6 "></div>
            <div class=" h-bar rounded-xl w-[100%] ml-1 bg-green-600"  v-if="pair.power === 7 "></div>
    </div>
</div>



</template>

<script>

import getPairs from '@/composables/getPairs'
import { computed, ref } from '@vue/runtime-core'



export default {

setup(){

        //Get Main Pairs
        const { pairs, error, load} = getPairs()
        load()

        //Obtener imagen flag de cada par
        const getImg = (img) =>{
            
            var imagesFlag = require.context('../../assets', false, /\.png$/)
            return imagesFlag('./' + img + ".png")
        }
       

        const pairsFiltered = computed(() => {

            var listplop2 = pairs.value
                return listplop2.sort((a, b) => (a.power > b.power) ? -1 : 1)

           
        })


        return{
            pairs, 
            getImg,
            pairsFiltered
        }
    }

    

}
</script>

<style>
.h-bar{
    height: 12px;
}
</style>
