import { ref } from "vue";
import { getAuth,updateProfile,  createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from '@/firebase/config.js';

import { collection, getDocs } from 'firebase/firestore'
import { doc, writeBatch, setDoc } from "firebase/firestore";
import { db } from '@/firebase/config.js';

const error = ref(null)
const resUser = ref(null)


const signup = async (email, password,displayName) =>{

    error.value = null
    

    try{

        const res = await createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            // Signed in 
                    resUser.value = userCredential.user;
        })

        updateProfile(resUser.value, {
            displayName: displayName
        })

        setDoc(doc(db, "users", resUser.value.uid), { 
            author_photoURL: "", 
            displayName: displayName,
            rol: ""
        })

        

        console.log(resUser.value.uid)



        error.value = null

        const paresGen = [{nombre: "AUD"}, {nombre: "CAD"}, {nombre: "CHF"}, {nombre: "EUR"},{nombre: "GBP"},{nombre: "JPY"},{nombre: "NZD"}, {nombre: "USD"} ];


        paresGen.forEach((element) => {
            
            setDoc(doc(db, "par_fx", element.nombre + resUser.value.uid), { 
                nombre: element.nombre, 
                origen: resUser.value.uid,
                power: 0
              

            })
        
        })

        // create SUP PAIRS 

        const AUDs = [
            {nombre: "AUDCAD", hijo:"CAD", id:"A-AUDCAD", id_inverso:"C-AUDCAD", especial: "yes" },
            {nombre: "AUDCHF", hijo:"CHF", id:"A-AUDCHF", id_inverso:"CH-AUDCHF", especial: "yes" },
            {nombre: "AUDJPY", hijo:"JPY", id:"A-AUDJPY", id_inverso:"J-AUDJPY", especial: "yes" },
            {nombre: "AUDNZD", hijo:"NZD", id:"A-AUDNZD", id_inverso:"N-AUDNZD", especial: "yes" },
            {nombre: "AUDUSD", hijo:"USD", id:"A-AUDUSD", id_inverso:"U-AUDUSD", especial: "yes" },
            {nombre: "EURAUD", hijo:"EUR", id:"A-EURAUD", id_inverso:"E-EURAUD", especial: "no" },
            {nombre: "GBPAUD", hijo:"GBP", id:"A-GBPAUD", id_inverso:"G-GBPAUD", especial: "no" }
                   
        ];

        AUDs.forEach((element) => {
            
            setDoc(doc(db, "pares_hijos", element.id + resUser.value.uid), { 
                nombreCorr: element.nombre, 
                id: element.id + resUser.value.uid,
                id_inverso: element.id_inverso + resUser.value.uid,
                papa: "AUD"+ resUser.value.uid,
                hijo: element.hijo,
                powerHijo: 0,
                powerPapa: 0,
                owner: resUser.value.uid,
                especial: element.especial,
                fav: "no"
                
            })
        
        })


        //----------------

        const CADs = [
            {nombre: "AUDCAD", hijo:"AUD", id:"C-AUDCAD", id_inverso:"A-AUDCAD", especial: "no" },
            {nombre: "CADCHF", hijo:"CHF", id:"C-CADCHF", id_inverso:"CH-CADCHF", especial: "yes" },
            {nombre: "CADJPY", hijo:"JPY", id:"C-CADJPY", id_inverso:"J-CADJPY", especial: "yes" },
            {nombre: "EURCAD", hijo:"EUR", id:"C-EURCAD", id_inverso:"E-EURCAD", especial: "no" },
            {nombre: "GBPCAD", hijo:"GBP", id:"C-GBPCAD", id_inverso:"G-GBPCAD", especial: "no" },
            {nombre: "NZDCAD", hijo:"NZD", id:"C-NZDCAD", id_inverso:"N-NZDCAD", especial: "no" },
            {nombre: "USDCAD", hijo:"USD", id:"C-USDCAD", id_inverso:"U-USDCAD", especial: "no" }
    
        ];

        CADs.forEach((element) => {
            
            setDoc(doc(db, "pares_hijos", element.id + resUser.value.uid), { 
                nombreCorr: element.nombre, 
                id: element.id + resUser.value.uid,
                id_inverso: element.id_inverso + resUser.value.uid,
                papa: "CAD"+ resUser.value.uid,
                hijo: element.hijo,
                powerHijo: 0,
                powerPapa: 0,
                owner: resUser.value.uid,
                especial: element.especial,
                fav: "no"
                
            })
        
        })




        //----------------

        const CHFs = [
            {nombre: "AUDCHF", hijo:"AUD", id:"CH-AUDCHF", id_inverso:"A-AUDCHF", especial: "no" },
            {nombre: "CADCHF", hijo:"CAD", id:"CH-CADCHF", id_inverso:"C-CADCHF", especial: "no" },
            {nombre: "CHFJPY", hijo:"JPY", id:"CH-CHFJPY", id_inverso:"J-CHFJPY", especial: "yes" },
            {nombre: "EURCHF", hijo:"EUR", id:"CH-EURCHF", id_inverso:"E-EURCHF", especial: "no" },
            {nombre: "GBPCHF", hijo:"GBP", id:"CH-GBPCHF", id_inverso:"G-GBPCHF", especial: "no" },
            {nombre: "NZDCHF", hijo:"NZD", id:"CH-NZDCHF", id_inverso:"N-NZDCHF", especial: "no" },
            {nombre: "USDCHF", hijo:"USD", id:"CH-USDCHF", id_inverso:"U-USDCHF", especial: "no" }
     
        ];

        CHFs.forEach((element) => {
            
            setDoc(doc(db, "pares_hijos", element.id + resUser.value.uid), { 
                nombreCorr: element.nombre, 
                id: element.id + resUser.value.uid,
                id_inverso: element.id_inverso + resUser.value.uid,
                papa: "CHF"+ resUser.value.uid,
                hijo: element.hijo,
                powerHijo: 0,
                powerPapa: 0,
                owner: resUser.value.uid,
                especial: element.especial,
                fav: "no"
                
            })
        
        })


         //----------------

         const EURs = [
            {nombre: "EURAUD", hijo:"AUD", id:"E-EURAUD", id_inverso:"A-EURAUD", especial: "no", especial: "yes" },
            {nombre: "EURCAD", hijo:"CAD", id:"E-EURCAD", id_inverso:"C-EURCAD", especial: "no", especial: "yes" },
            {nombre: "EURCHF", hijo:"CHF", id:"E-EURCHF", id_inverso:"CH-EURCHF", especial: "no", especial: "yes" },
            {nombre: "EURGBP", hijo:"GBP", id:"E-EURGBP", id_inverso:"G-EURGBP", especial: "no", especial: "yes" },
            {nombre: "EURJPY", hijo:"JPY", id:"E-EURJPY", id_inverso:"J-EURJPY", especial: "no", especial: "yes" },
            {nombre: "EURNZD", hijo:"NZD", id:"E-EURNZD", id_inverso:"N-EURNZD", especial: "no", especial: "yes" },
            {nombre: "EURUSD", hijo:"USD", id:"E-EURUSD", id_inverso:"U-EURUSD", especial: "no", especial: "yes" }
  
        ];

        EURs.forEach((element) => {
            
            setDoc(doc(db, "pares_hijos", element.id + resUser.value.uid), { 
                nombreCorr: element.nombre, 
                id: element.id + resUser.value.uid,
                id_inverso: element.id_inverso + resUser.value.uid,
                papa: "EUR"+ resUser.value.uid,
                hijo: element.hijo,
                powerHijo: 0,
                powerPapa: 0,
                owner: resUser.value.uid,
                especial: element.especial,
                fav: "no"
                
            })
        
        })




         //----------------

         const GBPs = [
            {nombre: "EURGBP", hijo:"EUR", id:"G-EURGBP", id_inverso:"E-EURGBP", especial: "no" },
            {nombre: "GBPAUD", hijo:"AUD", id:"G-GBPAUD", id_inverso:"A-GBPAUD", especial: "yes" },
            {nombre: "GBPCAD", hijo:"CAD", id:"G-GBPCAD", id_inverso:"C-GBPCAD", especial: "yes" },
            {nombre: "GBPCHF", hijo:"CHF", id:"G-GBPCHF", id_inverso:"CH-GBPCHF", especial: "yes" },
            {nombre: "GBPJPY", hijo:"JPY", id:"G-GBPJPY", id_inverso:"J-GBPJPY", especial: "yes" },
            {nombre: "GBPNZD", hijo:"NZD", id:"G-GBPNZD", id_inverso:"N-GBPNZD", especial: "yes" },
            {nombre: "GBPUSD", hijo:"USD", id:"G-GBPUSD", id_inverso:"U-GBPUSD", especial: "yes" }

                   
        ];

        GBPs.forEach((element) => {
            
            setDoc(doc(db, "pares_hijos", element.id + resUser.value.uid), { 
                nombreCorr: element.nombre, 
                id: element.id + resUser.value.uid,
                id_inverso: element.id_inverso + resUser.value.uid,
                papa: "GBP"+ resUser.value.uid,
                hijo: element.hijo,
                powerHijo: 0,
                powerPapa: 0,
                owner: resUser.value.uid,
                especial: element.especial,
                fav: "no"
                
            })
        
        })


        //----------------

        const JPYs = [
            {nombre: "AUDJPY", hijo:"AUD", id:"J-AUDJPY", id_inverso:"A-AUDJPY", especial: "no" },
            {nombre: "CADJPY", hijo:"CAD", id:"J-CADJPY", id_inverso:"C-CADJPY", especial: "no" },
            {nombre: "CHFJPY", hijo:"CHF", id:"J-CHFJPY", id_inverso:"CH-CHFJPY", especial: "no" },
            {nombre: "EURJPY", hijo:"EUR", id:"J-EURJPY", id_inverso:"E-EURJPY", especial: "no" },
            {nombre: "GBPJPY", hijo:"GBP", id:"J-GBPJPY", id_inverso:"G-GBPJPY", especial: "no" },
            {nombre: "NZDJPY", hijo:"NZD", id:"J-NZDJPY", id_inverso:"N-NZDJPY", especial: "no" },
            {nombre: "USDJPY", hijo:"USD", id:"J-USDJPY", id_inverso:"U-USDJPY", especial: "no" }
                
                   
        ];

        JPYs.forEach((element) => {
            
            setDoc(doc(db, "pares_hijos", element.id + resUser.value.uid), { 
                nombreCorr: element.nombre, 
                id: element.id + resUser.value.uid,
                id_inverso: element.id_inverso + resUser.value.uid,
                papa: "JPY"+ resUser.value.uid,
                hijo: element.hijo,
                powerHijo: 0,
                powerPapa: 0,
                owner: resUser.value.uid,
                especial: element.especial,
                fav: "no"
                
            })
        
        })


        //----------------

        const NZDs = [
            {nombre: "AUDNZD", hijo:"AUD", id:"N-AUDNZD", id_inverso:"A-AUDNZD", especial: "no" },
            {nombre: "EURNZD", hijo:"EUR", id:"N-EURNZD", id_inverso:"E-EURNZD", especial: "no" },
            {nombre: "GBPNZD", hijo:"GBP", id:"N-GBPNZD", id_inverso:"G-GBPNZD", especial: "no" },
            {nombre: "NZDCAD", hijo:"CAD", id:"N-NZDCAD", id_inverso:"C-NZDCAD", especial: "yes" },
            {nombre: "NZDCHF", hijo:"CHF", id:"N-NZDCHF", id_inverso:"CH-NZDCHF", especial: "yes" },
            {nombre: "NZDJPY", hijo:"JPY", id:"N-NZDJPY", id_inverso:"J-NZDJPY", especial: "yes" },
            {nombre: "NZDUSD", hijo:"USD", id:"N-NZDUSD", id_inverso:"U-NZDUSD", especial: "yes" }
                   
        ];

        NZDs.forEach((element) => {
            
            setDoc(doc(db, "pares_hijos", element.id + resUser.value.uid), { 
                nombreCorr: element.nombre, 
                id: element.id + resUser.value.uid,
                id_inverso: element.id_inverso + resUser.value.uid,
                papa: "NZD"+ resUser.value.uid,
                hijo: element.hijo,
                powerHijo: 0,
                powerPapa: 0,
                owner: resUser.value.uid,
                especial: element.especial,
                fav: "no"
                
            })
        
        })


         //----------------

         const USDs = [
            {nombre: "AUDUSD", hijo:"AUD", id:"U-AUDUSD", id_inverso:"A-AUDUSD", especial: "no" },
            {nombre: "EURUSD", hijo:"EUR", id:"U-EURUSD", id_inverso:"E-EURUSD", especial: "no" },
            {nombre: "GBPUSD", hijo:"GBP", id:"U-GBPUSD", id_inverso:"G-GBPUSD", especial: "no" },
            {nombre: "NZDUSD", hijo:"NZD", id:"U-NZDUSD", id_inverso:"N-NZDUSD", especial: "no" },
            {nombre: "USDCAD", hijo:"CAD", id:"U-USDCAD", id_inverso:"C-USDCAD", especial: "yes" },
            {nombre: "USDCHF", hijo:"CHF", id:"U-USDCHF", id_inverso:"CH-USDCHF", especial: "yes" },
            {nombre: "USDJPY", hijo:"JPY", id:"U-USDJPY", id_inverso:"J-USDJPY", especial: "yes" }
          
          
         
       
           
                   
        ];

        USDs.forEach((element) => {
            
            setDoc(doc(db, "pares_hijos", element.id + resUser.value.uid), { 
                nombreCorr: element.nombre, 
                id: element.id + resUser.value.uid,
                id_inverso: element.id_inverso + resUser.value.uid,
                papa: "USD"+ resUser.value.uid,
                hijo: element.hijo,
                powerHijo: 0,
                powerPapa: 0,
                owner: resUser.value.uid,
                especial: element.especial,
                fav: "no"
                
            })
        
        })




        





        return resUser
 


    } catch(err){

        console.log(err.message)
        error.value = err.message
    }


}
 

 
const useSignup = () =>{


    return {error, signup}
}

export default useSignup