import { ref } from "vue";
import { collection, getDocs } from 'firebase/firestore'
import { doc, onSnapshot, query, where } from "firebase/firestore";
import { db } from '@/firebase/config.js';
import getUser from '@/composables/getUser'



const getPairsWS = (conditionN,stateN,conditionP,stateP,typePower) => {

    const pairsP = ref([])
    const pairsN = ref([])

    const error = ref(null)

    const user = getUser()
    var uId = user.user._value.uid

    //console.log(conditionN + stateN)

    //trae listado de debiles
    const Nload = async () => {

        try{
            
            const q = query(collection(db, "par_fx"), where(typePower, conditionN, stateN));
            const paresCompletos = await onSnapshot(q, (querySnapshot) => {

                const paresFx = [];
                //console.log(querySnapshot)
                querySnapshot.forEach((doc) => {

                    const isId = doc._document.data.value.mapValue.fields.origen.stringValue

                    if (isId == uId){
                        paresFx.push(doc.data());
                    }

                    
                    
                })

                pairsN.value = paresFx 
                //console.log(pairsN.value)
                //console.log(urlImg)
            });
        }

        catch(err){
            error.value = err.message
            console.log(error.value)
      }
    }

 

    



    //trae listados de fuerte
    const Pload = async () => {

        try{
            const q = query(collection(db, "par_fx"), where(typePower, conditionP, stateP));
            const paresCompletos = await onSnapshot(q, (querySnapshot) => {

                const paresFx = [];
                //console.log(querySnapshot)
                querySnapshot.forEach((doc) => {
                    const isId = doc._document.data.value.mapValue.fields.origen.stringValue

                    if (isId == uId){
                        paresFx.push(doc.data());
                    }
                    
                })

                pairsP.value = paresFx 
                //console.log(pairs.value)
                //console.log(urlImg)
            });
        }

        catch(err){
            error.value = err.message
            console.log(error.value)
      }
    }









    return { pairsP,  pairsN,  error, Nload, Pload}

}

export default getPairsWS