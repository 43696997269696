<template>
  
      
           <div class="w-full place-content-center" v-for="pair in pairs" :key="pair">
                
                <div class=" flex place-items-center pt-1.5 pb-1.5 w-fit px-[5px]">
                    <span  class="  inline-block align-middle w-4 mr-3"><img  :src="getImg(pair.nombre)"  alt="">
                    <!-- <img src="../assets/logo.png"  alt="" class="" > -->
                    </span> 
                    <p class="w-[40px] text-[12px] tracking-[0.10em]">{{ pair.nombre }}</p>


                    <select class="select select-xs h-8 rounded-md bg-none pl-5 pr-5 text-[13px] font-light" @change="handleChange(pair.nombre)"  :value="pair.powerv2" :placeholder="pair.nombre" :id="pair.nombre + '_power'" >
                        <option v-for="option in options" :key="option">
                            {{  option }}
                        </option>
                    </select>

                    
                    

                    
                    
                </div> 

           
           </div>

          
       


              
             
    
        
 
 
  

  

     


</template>

<script>

import getSubPairsInd from '@/composables/getSubPairsInd'
import getPairs from '@/composables/getPairs'
import { ref } from '@vue/reactivity'
import { db } from '@/firebase/config.js';
import { doc, updateDoc, writeBatch } from "firebase/firestore"; 
import { onMounted, onUpdated } from '@vue/runtime-core';


export default {
emits:['sendPower'],
props: ['pair'],
component: {  },
setup(context){

    const { pairs, error, load} = getPairs()
    load()

     //Get Sub Pairs
    
    // const { subPairsInd, errorSub, loadSubPairsInd, poderPapaResult} = getSubPairsInd('IND')
    // loadSubPairsInd()

    const powerValue = ref("")

    
    


        const handleChange = (id) =>{
            let selectValue = document.getElementById(id+"_power");
            let poderHijoNumber = parseInt(selectValue.value) 
            let poderHijoNumberOpuesto = !poderHijoNumber

            updateDoc(doc(db, "par_fx", id), {  
            powerv2: parseInt(selectValue.value )          
            })

           

        }

        //Obtener imagen flag de cada par
        const getImg = (img) =>{
           
            var imagesFlag = require.context('../../assets', false, /\.png$/)
            return imagesFlag('./' + img + ".png")
        }

        
        
        onUpdated(() => {
            //console.log(poderPapaResult['value']);
           // sendEvent1()

            //powerValue.value = poderPapaResult

        })


        // function sendEvent1(){
        //     //console.log(powerValue)
        //     context.emit('sendPower', powerValue)

        // }

     
        


        return { 
            pairs,
            options: [7,6,5,4,3,2,1,0,-1,-2,-3,-4,-5,-6,-7],
            handleChange,
            getImg,
            powerValue
            
            
            // updatePairs
            
  
        }


        

    }

}
</script>

<style>




</style>