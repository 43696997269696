<template>
 


    <div v-for="pair in pairs" :key="pair" class="w-full inline-block break-inside-avoid " style="">

        <SinglePair  :pair="pair" class="mb-5 pair_score" /> 

    </div>





</template>

<script>

import SinglePair from '@/components/score/SinglePair.vue'
import { ref } from '@vue/reactivity'



export default {
    emits:['sendPowFinal'],
    props: ['pairs'],
    components: { SinglePair },
    setup(props, context){

       
     


        
      return{
        
        
        

      }
    }


}
</script>

<style>

.PairBox{
  width: 150px;
  margin: 5px auto;
}



</style>