import { initializeApp } from 'firebase/app';
import { getFirestore} from 'firebase/firestore'
import { getAuth } from "firebase/auth";




const firebaseConfig = {
    apiKey: "AIzaSyAsm4mmF75fAJqx3Qs8mvw92opnN_DIl-Y",
    authDomain: "fx-app-6e356.firebaseapp.com",
    projectId: "fx-app-6e356",
    storageBucket: "fx-app-6e356.appspot.com",
    messagingSenderId: "304654786797",
    appId: "1:304654786797:web:1658d17416e9c7037a6ce4"
  };


  // init firebase
  const app = initializeApp(firebaseConfig)


  // Initialize Cloud Firestore and get a reference to the service
  const db = getFirestore(app)
  const auth = getAuth(app);

 



  export { db, auth }