import { ref } from "vue";
import { collection, getDocs } from 'firebase/firestore'
import { doc, writeBatch } from "firebase/firestore";
import { db } from '@/firebase/config.js';
import getUser from '@/composables/getUser'




const updatePowerPairs = () => {

    const user = getUser()
    var uId = user.user._value.uid

    
    const errorUp = ref(null)
    const upPower = async (version) => {

        try{

           
            const powerValueAUDplop = document.getElementById('AUD_power')
            const powerValueCAD = document.getElementById('CAD_power')
            const powerValueCHF = document.getElementById('CHF_power')
            const powerValueEUR = document.getElementById('EUR_power')
            const powerValueGBP = document.getElementById('GBP_power')
            const powerValueJPY = document.getElementById('JPY_power')
            const powerValueNZD = document.getElementById('NZD_power')
            const powerValueUSD = document.getElementById('USD_power')

            // let aud = powerValueAUDplop.getAttribute('powervalue')
            // let cad = powerValueCAD.getAttribute('powervalue')
            // let chf = powerValueCHF.getAttribute('powervalue')
            // let eur = powerValueEUR.getAttribute('powervalue')
            // let gbp = powerValueGBP.getAttribute('powervalue')
            // let jpy = powerValueJPY.getAttribute('powervalue')
            // let nzd = powerValueNZD.getAttribute('powervalue')
            // let usd = powerValueUSD.getAttribute('powervalue')

            let aud;
            let cad;
            let chf;
            let eur;
            let gbp;
            let jpy;
            let nzd;
            let usd;

        
            if (version === "v1"){ 
        
                    console.log("entra")
                    aud = powerValueAUDplop.getAttribute('powervalue')
                    cad = powerValueCAD.getAttribute('powervalue')
                    chf = powerValueCHF.getAttribute('powervalue')
                    eur = powerValueEUR.getAttribute('powervalue')
                    gbp = powerValueGBP.getAttribute('powervalue')
                    jpy = powerValueJPY.getAttribute('powervalue')
                    nzd = powerValueNZD.getAttribute('powervalue')
                    usd = powerValueUSD.getAttribute('powervalue')    

            }

            if (version === "v2"){ 
        
                console.log("v2")
                aud = powerValueAUDplop.value
                cad = powerValueCAD.value
                chf = powerValueCHF.value
                eur = powerValueEUR.value
                gbp = powerValueGBP.value
                jpy = powerValueJPY.value
                nzd = powerValueNZD.value
                usd = powerValueUSD.value    

             }


        

            const batch = writeBatch(db)

            //console.log('aud:' + aud)

            if (version === "v1"){ 

            //AUD
            const audPair = doc(db, "par_fx", "AUD" + uId);
            batch.update(audPair, {"power": parseInt(aud) })

            const a_audcad = doc(db, "pares_hijos", "A-AUDCAD" + uId);
            batch.update(a_audcad, {"powerHijoDad": parseInt(cad) })

            const a_audchf = doc(db, "pares_hijos", "A-AUDCHF" + uId);
            batch.update(a_audchf, {"powerHijoDad": parseInt(chf) })

            const a_audjpy = doc(db, "pares_hijos", "A-AUDJPY" + uId);
            batch.update(a_audjpy, {"powerHijoDad": parseInt(jpy) })

            const a_audnzd = doc(db, "pares_hijos", "A-AUDNZD" + uId);
            batch.update(a_audnzd, {"powerHijoDad": parseInt(nzd) })

            const a_audusd = doc(db, "pares_hijos", "A-AUDUSD" + uId);
            batch.update(a_audusd, {"powerHijoDad": parseInt(usd) })

            const a_euraud = doc(db, "pares_hijos", "A-EURAUD" + uId);
            batch.update(a_euraud, {"powerHijoDad": parseInt(eur) })

            const a_gbpaud = doc(db, "pares_hijos", "A-GBPAUD" + uId);
            batch.update(a_gbpaud, {"powerHijoDad": parseInt(gbp) })



            //CAD

            const cadPair = doc(db, "par_fx", "CAD"+ uId);
            batch.update(cadPair, {"power": parseInt(cad) })

            const c_audcad = doc(db, "pares_hijos", "C-AUDCAD"+ uId);
            batch.update(c_audcad, {"powerHijoDad": parseInt(aud) })

            const c_cadchf = doc(db, "pares_hijos", "C-CADCHF"+ uId);
            batch.update(c_cadchf, {"powerHijoDad": parseInt(chf) })

            const c_cadjpy = doc(db, "pares_hijos", "C-CADJPY"+ uId);
            batch.update(c_cadjpy, {"powerHijoDad": parseInt(jpy) })

            const c_eurcad = doc(db, "pares_hijos", "C-EURCAD"+ uId);
            batch.update(c_eurcad, {"powerHijoDad": parseInt(eur) })

            const c_gbpcad = doc(db, "pares_hijos", "C-GBPCAD"+ uId);
            batch.update(c_gbpcad, {"powerHijoDad": parseInt(gbp) })

            const c_nzdcad = doc(db, "pares_hijos", "C-NZDCAD"+ uId);
            batch.update(c_nzdcad, {"powerHijoDad": parseInt(nzd) })

            const c_usdcad = doc(db, "pares_hijos", "C-USDCAD"+ uId);
            batch.update(c_usdcad, {"powerHijoDad": parseInt(usd) })

  
            
            //CHF


            const chfPair = doc(db, "par_fx", "CHF" + uId);
            batch.update(chfPair, {"power": parseInt(chf) })

            const ch_audchf = doc(db, "pares_hijos", "CH-AUDCHF" + uId);
            batch.update(ch_audchf, {"powerHijoDad": parseInt(aud) })

            const ch_cadchf = doc(db, "pares_hijos", "CH-CADCHF" + uId);
            batch.update(ch_cadchf, {"powerHijoDad": parseInt(cad) })

            const ch_chfjpy = doc(db, "pares_hijos", "CH-CHFJPY" + uId);
            batch.update(ch_chfjpy, {"powerHijoDad": parseInt(jpy) })

            const ch_eurchf = doc(db, "pares_hijos", "CH-EURCHF" + uId);
            batch.update(ch_eurchf, {"powerHijoDad": parseInt(eur) })

            const ch_gbpchf = doc(db, "pares_hijos", "CH-GBPCHF" + uId);
            batch.update(ch_gbpchf, {"powerHijoDad": parseInt(gbp) })

            const ch_nzdchf = doc(db, "pares_hijos", "CH-NZDCHF" + uId);
            batch.update(ch_nzdchf, {"powerHijoDad": parseInt(nzd) })

            const ch_usdchf = doc(db, "pares_hijos", "CH-USDCHF" + uId);
            batch.update(ch_usdchf, {"powerHijoDad": parseInt(usd) })







            //EUR

            const eurPair = doc(db, "par_fx", "EUR"+ uId);
            batch.update(eurPair, {"power": parseInt(eur) })

            const e_euraud = doc(db, "pares_hijos", "E-EURAUD"+ uId);
            batch.update(e_euraud, {"powerHijoDad": parseInt(aud) })

            const e_eurcad = doc(db, "pares_hijos", "E-EURCAD"+ uId);
            batch.update(e_eurcad, {"powerHijoDad": parseInt(cad) })

            const e_eurchf  = doc(db, "pares_hijos", "E-EURCHF"+ uId);
            batch.update(e_eurchf, {"powerHijoDad": parseInt(chf) })

            const e_eurgbp = doc(db, "pares_hijos", "E-EURGBP"+ uId);
            batch.update(e_eurgbp, {"powerHijoDad": parseInt(gbp) })

            const e_eurjpy = doc(db, "pares_hijos", "E-EURJPY"+ uId);
            batch.update(e_eurjpy, {"powerHijoDad": parseInt(jpy) })

            const e_eurnzd = doc(db, "pares_hijos", "E-EURNZD"+ uId);
            batch.update(e_eurnzd, {"powerHijoDad": parseInt(nzd) })

            const e_eurusd = doc(db, "pares_hijos", "E-EURUSD"+ uId);
            batch.update(e_eurusd, {"powerHijoDad": parseInt(usd) })







            //GBP

            const gbpPair = doc(db, "par_fx", "GBP"+ uId);
            batch.update(gbpPair, {"power": parseInt(gbp) })

            const g_eurgbp = doc(db, "pares_hijos", "G-EURGBP"+ uId);
            batch.update(g_eurgbp, {"powerHijoDad": parseInt(eur) })

            const g_gbpaud = doc(db, "pares_hijos", "G-GBPAUD"+ uId);
            batch.update(g_gbpaud, {"powerHijoDad": parseInt(aud) })

            const g_gbpcad = doc(db, "pares_hijos", "G-GBPCAD"+ uId);
            batch.update(g_gbpcad, {"powerHijoDad": parseInt(cad) })

            const g_gbpchf = doc(db, "pares_hijos", "G-GBPCHF"+ uId);
            batch.update(g_gbpchf, {"powerHijoDad": parseInt(chf) })

            const g_gbpjpy = doc(db, "pares_hijos", "G-GBPJPY"+ uId);
            batch.update(g_gbpjpy, {"powerHijoDad": parseInt(jpy) })

            const g_gbpnzd = doc(db, "pares_hijos", "G-GBPNZD"+ uId);
            batch.update(g_gbpnzd, {"powerHijoDad": parseInt(nzd) })

            const g_gbpusd = doc(db, "pares_hijos", "G-GBPUSD"+ uId);
            batch.update(g_gbpusd, {"powerHijoDad": parseInt(usd) })



            //JPY

            const jpyPair = doc(db, "par_fx", "JPY"+ uId);
            batch.update(jpyPair, {"power": parseInt(jpy) })

            const j_audjpy = doc(db, "pares_hijos", "J-AUDJPY"+ uId);
            batch.update(j_audjpy, {"powerHijoDad": parseInt(aud) })

            const j_cadjpy = doc(db, "pares_hijos", "J-CADJPY"+ uId);
            batch.update(j_cadjpy, {"powerHijoDad": parseInt(cad) })

            const j_chfjpy = doc(db, "pares_hijos", "J-CHFJPY"+ uId);
            batch.update(j_chfjpy, {"powerHijoDad": parseInt(chf) })

            const j_eurjpy = doc(db, "pares_hijos", "J-EURJPY"+ uId);
            batch.update(j_eurjpy, {"powerHijoDad": parseInt(eur) })

            const j_gbpjpy = doc(db, "pares_hijos", "J-GBPJPY"+ uId);
            batch.update(j_gbpjpy, {"powerHijoDad": parseInt(gbp) })

            const j_nzdjpy = doc(db, "pares_hijos", "J-NZDJPY"+ uId);
            batch.update(j_nzdjpy, {"powerHijoDad": parseInt(nzd) })

            const j_usdjpy = doc(db, "pares_hijos", "J-USDJPY"+ uId);
            batch.update(j_usdjpy, {"powerHijoDad": parseInt(usd) })






            //NZD

            const nzdPair = doc(db, "par_fx", "NZD"+ uId);
            batch.update(nzdPair, {"power": parseInt(nzd) })

            const n_audnzd = doc(db, "pares_hijos", "N-AUDNZD"+ uId);
            batch.update(n_audnzd, {"powerHijoDad": parseInt(aud) })

            const n_eurnzd = doc(db, "pares_hijos", "N-EURNZD"+ uId);
            batch.update(n_eurnzd, {"powerHijoDad": parseInt(eur) })

            const n_gbpnzd = doc(db, "pares_hijos", "N-GBPNZD"+ uId);
            batch.update(n_gbpnzd, {"powerHijoDad": parseInt(gbp) })

            const n_nzdcad = doc(db, "pares_hijos", "N-NZDCAD"+ uId);
            batch.update(n_nzdcad, {"powerHijoDad": parseInt(cad) })

            const n_nzdchf = doc(db, "pares_hijos", "N-NZDCHF"+ uId);
            batch.update(n_nzdchf, {"powerHijoDad": parseInt(chf) })

            const n_nzdjpy = doc(db, "pares_hijos", "N-NZDJPY"+ uId);
            batch.update(n_nzdjpy, {"powerHijoDad": parseInt(jpy) })

            const n_nzdusd = doc(db, "pares_hijos", "N-NZDUSD"+ uId);
            batch.update(n_nzdusd, {"powerHijoDad": parseInt(usd) })

            


            //USD
            const usdPair = doc(db, "par_fx", "USD"+ uId);
            batch.update(usdPair, {"power": parseInt(usd) })

            const u_audusd = doc(db, "pares_hijos", "U-AUDUSD"+ uId);
            batch.update(u_audusd, {"powerHijoDad": parseInt(aud) })

            const u_eurusd = doc(db, "pares_hijos", "U-EURUSD"+ uId);
            batch.update(u_eurusd, {"powerHijoDad": parseInt(eur) })

            const u_gbpusd = doc(db, "pares_hijos", "U-GBPUSD"+ uId);
            batch.update(u_gbpusd, {"powerHijoDad": parseInt(gbp) })

            const u_nzdusd = doc(db, "pares_hijos", "U-NZDUSD"+ uId);
            batch.update(u_nzdusd, {"powerHijoDad": parseInt(nzd) })

            const u_usdcad = doc(db, "pares_hijos", "U-USDCAD"+ uId);
            batch.update(u_usdcad, {"powerHijoDad": parseInt(cad) })

            const u_usdchf = doc(db, "pares_hijos", "U-USDCHF"+ uId);
            batch.update(u_usdchf, {"powerHijoDad": parseInt(chf) })

            const u_usdjpy = doc(db, "pares_hijos", "U-USDJPY"+ uId);
            batch.update(u_usdjpy, {"powerHijoDad": parseInt(jpy) })


            await batch.commit();

            }


            if (version === "v2"){ 

                //AUD
                const audPair = doc(db, "par_fx", "AUD"+ uId);
                batch.update(audPair, {"powerv2": parseInt(aud) })
    
                const a_audcad = doc(db, "pares_hijos", "A-AUDCAD"+ uId);
                batch.update(a_audcad, {"powerHijoDadv2": parseInt(cad) })
    
                const a_audchf = doc(db, "pares_hijos", "A-AUDCHF"+ uId);
                batch.update(a_audchf, {"powerHijoDadv2": parseInt(chf) })
    
                const a_audjpy = doc(db, "pares_hijos", "A-AUDJPY"+ uId);
                batch.update(a_audjpy, {"powerHijoDadv2": parseInt(jpy) })
    
                const a_audnzd = doc(db, "pares_hijos", "A-AUDNZD"+ uId);
                batch.update(a_audnzd, {"powerHijoDadv2": parseInt(nzd) })
    
                const a_audusd = doc(db, "pares_hijos", "A-AUDUSD"+ uId);
                batch.update(a_audusd, {"powerHijoDadv2": parseInt(usd) })
    
                const a_euraud = doc(db, "pares_hijos", "A-EURAUD"+ uId);
                batch.update(a_euraud, {"powerHijoDadv2": parseInt(eur) })
    
                const a_gbpaud = doc(db, "pares_hijos", "A-GBPAUD"+ uId);
                batch.update(a_gbpaud, {"powerHijoDadv2": parseInt(gbp) })
    
    
    
                //CAD
    
                const cadPair = doc(db, "par_fx", "CAD");
                batch.update(cadPair, {"powerv2": parseInt(cad) })
    
                const c_audcad = doc(db, "pares_hijos", "C-AUDCAD");
                batch.update(c_audcad, {"powerHijoDadv2": parseInt(aud) })
    
                const c_cadchf = doc(db, "pares_hijos", "C-CADCHF");
                batch.update(c_cadchf, {"powerHijoDadv2": parseInt(chf) })
    
                const c_cadjpy = doc(db, "pares_hijos", "C-CADJPY");
                batch.update(c_cadjpy, {"powerHijoDadv2": parseInt(jpy) })
    
                const c_eurcad = doc(db, "pares_hijos", "C-EURCAD");
                batch.update(c_eurcad, {"powerHijoDadv2": parseInt(eur) })
    
                const c_gbpcad = doc(db, "pares_hijos", "C-GBPCAD");
                batch.update(c_gbpcad, {"powerHijoDadv2": parseInt(gbp) })
    
                const c_nzdcad = doc(db, "pares_hijos", "C-NZDCAD");
                batch.update(c_nzdcad, {"powerHijoDadv2": parseInt(nzd) })
    
                const c_usdcad = doc(db, "pares_hijos", "C-USDCAD");
                batch.update(c_usdcad, {"powerHijoDadv2": parseInt(usd) })
    
      
                
                //CHF
    
    
                const chfPair = doc(db, "par_fx", "CHF");
                batch.update(chfPair, {"powerv2": parseInt(chf) })
    
                const ch_audchf = doc(db, "pares_hijos", "CH-AUDCHF");
                batch.update(ch_audchf, {"powerHijoDadv2": parseInt(aud) })
    
                const ch_cadchf = doc(db, "pares_hijos", "CH-CADCHF");
                batch.update(ch_cadchf, {"powerHijoDadv2": parseInt(cad) })
    
                const ch_chfjpy = doc(db, "pares_hijos", "CH-CHFJPY");
                batch.update(ch_chfjpy, {"powerHijoDadv2": parseInt(jpy) })
    
                const ch_eurchf = doc(db, "pares_hijos", "CH-EURCHF");
                batch.update(ch_eurchf, {"powerHijoDadv2": parseInt(eur) })
    
                const ch_gbpchf = doc(db, "pares_hijos", "CH-GBPCHF");
                batch.update(ch_gbpchf, {"powerHijoDadv2": parseInt(gbp) })
    
                const ch_nzdchf = doc(db, "pares_hijos", "CH-NZDCHF");
                batch.update(ch_nzdchf, {"powerHijoDadv2": parseInt(nzd) })
    
                const ch_usdchf = doc(db, "pares_hijos", "CH-USDCHF");
                batch.update(ch_usdchf, {"powerHijoDadv2": parseInt(usd) })
    
    
    
    
    
    
    
                //EUR
    
                const eurPair = doc(db, "par_fx", "EUR");
                batch.update(eurPair, {"powerv2": parseInt(eur) })
    
                const e_euraud = doc(db, "pares_hijos", "E-EURAUD");
                batch.update(e_euraud, {"powerHijoDadv2": parseInt(aud) })
    
                const e_eurcad = doc(db, "pares_hijos", "E-EURCAD");
                batch.update(e_eurcad, {"powerHijoDadv2": parseInt(cad) })
    
                const e_eurchf  = doc(db, "pares_hijos", "E-EURCHF");
                batch.update(e_eurchf, {"powerHijoDadv2": parseInt(chf) })
    
                const e_eurgbp = doc(db, "pares_hijos", "E-EURGBP");
                batch.update(e_eurgbp, {"powerHijoDadv2": parseInt(gbp) })
    
                const e_eurjpy = doc(db, "pares_hijos", "E-EURJPY");
                batch.update(e_eurjpy, {"powerHijoDadv2": parseInt(jpy) })
    
                const e_eurnzd = doc(db, "pares_hijos", "E-EURNZD");
                batch.update(e_eurnzd, {"powerHijoDadv2": parseInt(nzd) })
    
                const e_eurusd = doc(db, "pares_hijos", "E-EURUSD");
                batch.update(e_eurusd, {"powerHijoDadv2": parseInt(usd) })
    
    
    
    
    
    
    
                //GBP
    
                const gbpPair = doc(db, "par_fx", "GBP");
                batch.update(gbpPair, {"powerv2": parseInt(gbp) })
    
                const g_eurgbp = doc(db, "pares_hijos", "G-EURGBP");
                batch.update(g_eurgbp, {"powerHijoDadv2": parseInt(eur) })
    
                const g_gbpaud = doc(db, "pares_hijos", "G-GBPAUD");
                batch.update(g_gbpaud, {"powerHijoDadv2": parseInt(aud) })
    
                const g_gbpcad = doc(db, "pares_hijos", "G-GBPCAD");
                batch.update(g_gbpcad, {"powerHijoDadv2": parseInt(cad) })
    
                const g_gbpchf = doc(db, "pares_hijos", "G-GBPCHF");
                batch.update(g_gbpchf, {"powerHijoDadv2": parseInt(chf) })
    
                const g_gbpjpy = doc(db, "pares_hijos", "G-GBPJPY");
                batch.update(g_gbpjpy, {"powerHijoDadv2": parseInt(jpy) })
    
                const g_gbpnzd = doc(db, "pares_hijos", "G-GBPNZD");
                batch.update(g_gbpnzd, {"powerHijoDadv2": parseInt(nzd) })
    
                const g_gbpusd = doc(db, "pares_hijos", "G-GBPUSD");
                batch.update(g_gbpusd, {"powerHijoDadv2": parseInt(usd) })
    
    
    
                //JPY
    
                const jpyPair = doc(db, "par_fx", "JPY");
                batch.update(jpyPair, {"powerv2": parseInt(jpy) })
    
                const j_audjpy = doc(db, "pares_hijos", "J-AUDJPY");
                batch.update(j_audjpy, {"powerHijoDadv2": parseInt(aud) })
    
                const j_cadjpy = doc(db, "pares_hijos", "J-CADJPY");
                batch.update(j_cadjpy, {"powerHijoDadv2": parseInt(cad) })
    
                const j_chfjpy = doc(db, "pares_hijos", "J-CHFJPY");
                batch.update(j_chfjpy, {"powerHijoDadv2": parseInt(chf) })
    
                const j_eurjpy = doc(db, "pares_hijos", "J-EURJPY");
                batch.update(j_eurjpy, {"powerHijoDadv2": parseInt(eur) })
    
                const j_gbpjpy = doc(db, "pares_hijos", "J-GBPJPY");
                batch.update(j_gbpjpy, {"powerHijoDadv2": parseInt(gbp) })
    
                const j_nzdjpy = doc(db, "pares_hijos", "J-NZDJPY");
                batch.update(j_nzdjpy, {"powerHijoDadv2": parseInt(nzd) })
    
                const j_usdjpy = doc(db, "pares_hijos", "J-USDJPY");
                batch.update(j_usdjpy, {"powerHijoDadv2": parseInt(usd) })
    
    
    
    
    
    
                //NZD
    
                const nzdPair = doc(db, "par_fx", "NZD");
                batch.update(nzdPair, {"powerv2": parseInt(nzd) })
    
                const n_audnzd = doc(db, "pares_hijos", "N-AUDNZD");
                batch.update(n_audnzd, {"powerHijoDadv2": parseInt(aud) })
    
                const n_eurnzd = doc(db, "pares_hijos", "N-EURNZD");
                batch.update(n_eurnzd, {"powerHijoDadv2": parseInt(eur) })
    
                const n_gbpnzd = doc(db, "pares_hijos", "N-GBPNZD");
                batch.update(n_gbpnzd, {"powerHijoDadv2": parseInt(gbp) })
    
                const n_nzdcad = doc(db, "pares_hijos", "N-NZDCAD");
                batch.update(n_nzdcad, {"powerHijoDadv2": parseInt(cad) })
    
                const n_nzdchf = doc(db, "pares_hijos", "N-NZDCHF");
                batch.update(n_nzdchf, {"powerHijoDadv2": parseInt(chf) })
    
                const n_nzdjpy = doc(db, "pares_hijos", "N-NZDJPY");
                batch.update(n_nzdjpy, {"powerHijoDadv2": parseInt(jpy) })
    
                const n_nzdusd = doc(db, "pares_hijos", "N-NZDUSD");
                batch.update(n_nzdusd, {"powerHijoDadv2": parseInt(usd) })
    
                
    
    
                //USD
                const usdPair = doc(db, "par_fx", "USD");
                batch.update(usdPair, {"powerv2": parseInt(usd) })
    
                const u_audusd = doc(db, "pares_hijos", "U-AUDUSD");
                batch.update(u_audusd, {"powerHijoDadv2": parseInt(aud) })
    
                const u_eurusd = doc(db, "pares_hijos", "U-EURUSD");
                batch.update(u_eurusd, {"powerHijoDadv2": parseInt(eur) })
    
                const u_gbpusd = doc(db, "pares_hijos", "U-GBPUSD");
                batch.update(u_gbpusd, {"powerHijoDadv2": parseInt(gbp) })
    
                const u_nzdusd = doc(db, "pares_hijos", "U-NZDUSD");
                batch.update(u_nzdusd, {"powerHijoDadv2": parseInt(nzd) })
    
                const u_usdcad = doc(db, "pares_hijos", "U-USDCAD");
                batch.update(u_usdcad, {"powerHijoDadv2": parseInt(cad) })
    
                const u_usdchf = doc(db, "pares_hijos", "U-USDCHF");
                batch.update(u_usdchf, {"powerHijoDadv2": parseInt(chf) })
    
                const u_usdjpy = doc(db, "pares_hijos", "U-USDJPY");
                batch.update(u_usdjpy, {"powerHijoDadv2": parseInt(jpy) })
    
    
                await batch.commit();
    
                }

        }

        catch(err){
            errorUp.value = err.message
            console.log(errorUp.value)
      }
    }

    return { upPower, errorUp }

}

export default updatePowerPairs

