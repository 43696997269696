import { ref } from "vue";
import { collection, getDocs } from 'firebase/firestore'
import { doc, onSnapshot, query, where } from "firebase/firestore";
import { db } from '@/firebase/config.js';
import getUser from '@/composables/getUser'



const getPairs = () => {

    const pairs = ref([])
    const error = ref(null)

    const user = getUser()
    var uId = user.user._value.uid

    //console.log(uId)
    

    const load = async () => {

        try{
            const q = query(collection(db, "par_fx"), where("origen", "==", uId));
            const paresCompletos = await onSnapshot(q, (querySnapshot) => {

        
                const paresFx = [];
                
                querySnapshot.forEach((doc) => {
                    paresFx.push(doc.data());
                    
                })

                pairs.value = paresFx 
                //console.log(pairs.value)
                //console.log(urlImg)
            });
        }

        catch(err){
            error.value = err.message
            console.log(error.value)
      }
    }

    return { pairs, error, load}

}

export default getPairs